<template>
  <div class="main zhu base-w" :class="{ AllGoods: $parent.showAllGoods }">
    <!-- 头部 -->
    <div class="head">
      <span>
        <font class="headT1">{{ $fanyi("订单内容") }}</font>

        <span class="orderSnBox"
          >{{ $fanyi("订单号") }} ：{{ $parent.datas.order_sn }}</span
        >
      </span>
    </div>

    <!-- 订单列表 -->
    <div class="order">
      <ul>
        <li
          class="inputBox"
          :style="
            ['obligation', 'OrderDetails', 'purchased'].indexOf(
              $parent.orderStatus
            ) != -1
              ? 'flex: 0 0 186px'
              : ''
          "
          @click="
            $parent.checked = !$parent.checked;
            $parent.allChoice();
          "
        >
          <input
            class="headCheckBox"
            style="margin-right: 10px"
            type="checkbox"
            name=""
            id=""
            v-model="$parent.checked"
            @change="$parent.allChoice"
          />
          {{ $fanyi("全选") }}
        </li>
        <li class="goods" style="padding: 0">{{ $fanyi("选定的产品") }}</li>
        <li class="detail">{{ $fanyi("描述") }}</li>
        <li class="num">
          <span
            :style="
              ['obligation', 'OrderDetails', 'purchased'].indexOf(
                $parent.orderStatus
              ) != -1
                ? 'margin-left: 20px'
                : ''
            "
            >{{ $fanyi("单价") }}</span
          >
        </li>
        <li class="num">{{ $fanyi("单位") }}</li>
        <!-- 调查在库数量 -->
        <li
          class="price"
          v-if="
            ['obligation', 'OrderDetails', 'purchased', 'purchase'].indexOf(
              $parent.orderStatus
            ) != -1
          "
        >
          {{ $fanyi("调查在库数量") }}
        </li>

        <li class="total">{{ $fanyi("总价") }}</li>
        <!-- v-if="['purchased', 'purchase'].indexOf($parent.orderStatus) != -1" -->

        <li class="options">{{ $fanyi("附加服务") }}</li>
        <li class="remark">{{ $fanyi("备注") }}</li>
        <!-- 估计的产品进口细节 -->
        <li
          v-if="
            ['obligation', 'purchased', 'purchase'].indexOf(
              $parent.orderStatus
            ) != -1
          "
          class="constDetail"
        >
          {{ $fanyi("导入的详细信息") }}
        </li>
      </ul>
      <div
        class="goodsBox"
        :style="!$parent.showAllGoods ? 'max-height:300px' : ''"
      >
        <div v-for="(aitem, aindex) in $parent.newList" :key="aindex">
          <h1>
            <div
              class="checkedBox"
              @click="
                aitem.checked = !aitem.checked;
                $parent.shopAll(aindex);
              "
            >
              <input
                type="checkbox"
                name=""
                id=""
                v-model="aitem.checked"
                @change="$parent.shopAll(aindex)"
              />
            </div>
            {{ $fanyi("店铺") }}：{{
              aitem.shop_name_translate || aitem.shop_name
            }}
          </h1>
          <!-- {{ aitem.list }} -->
          <div class="goodsCon">
            <ul v-for="(item, goodsListIndex) in aitem.list" :key="item.id">
              <!-- 全选 -->
              <li
                class="inputBox"
                @click="
                  item.checked = !item.checked;
                  $parent.radio(aindex);
                "
              >
                <input
                  type="checkbox"
                  name=""
                  id=""
                  @change="$parent.radio(aindex)"
                  v-model="item.checked"
                />
                <!-- 商品番号 -->
                <span class="rowNumber">{{ item.sorting }}</span>
                <!-- <img :src="item.pic" alt="" /> -->
                <el-popover placement="right" trigger="hover">
                  <img
                    :src="item.pic"
                    alt=""
                    style="width: 300px; height: 300px"
                  />

                  <img
                    @click="$fun.toCommodityDetails(item.goods_id)"
                    :src="item.pic"
                    alt=""
                    slot="reference"
                  />
                </el-popover>

                <!-- <img :src="item.pic" alt="item.goods_title" /> -->
              </li>
              <!-- 产品 -->
              <li class="goods">
                <el-popover placement="bottom" width="400" trigger="hover">
                  {{ item.goods_title }}
                  <div
                    class="showGoodsTitle"
                    :title="item.goods_title"
                    slot="reference"
                    @click="$fun.toCommodityDetails(item.goods_id)"
                  >
                    <font>{{ item.goods_title }}</font>
                  </div>
                </el-popover>
              </li>
              <!-- 详细 -->
              <li
                class="detail"
                :class="
                  item.detailWindow == true
                    ? 'active goodsDetail'
                    : 'goodsDetail'
                "
              >
                <!-- 展示 -->
                <el-popover placement="bottom" trigger="hover">
                  <div class="goodsDetailAll">
                    <div
                      class="goodsDetailAllOneBox"
                      v-for="(detailItem, detailIndex) in item.detail"
                      :key="detailIndex"
                      :title="detailItem.key + ':' + detailItem.value"
                    >
                      <h1 class="detailTitle">{{ detailItem.key }}</h1>
                      <p>
                        <span class="detailBody">{{ detailItem.value }}</span>
                      </p>
                    </div>
                  </div>

                  <div
                    slot="reference"
                    class="showDetail"
                    v-if="
                      ['temporary', 'editing'].indexOf($parent.orderStatus) ==
                        -1
                    "
                  >
                    <p
                      v-for="(bitem, bindex) in item.detail"
                      :key="bitem.key + bindex"
                      :title="bitem.key + ':' + bitem.value"
                      class="goodsDetailOpt ispay"
                      @click.stop="
                        ['temporary', 'editing'].indexOf($parent.orderStatus) !=
                        -1
                          ? $parent.openDetailWindow(item)
                          : ''
                      "
                    >
                      {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                    </p>
                    <img
                      @click.stop="
                        ['temporary', 'editing'].indexOf($parent.orderStatus) !=
                        -1
                          ? $parent.openDetailWindow(item)
                          : ''
                      "
                      v-if="
                        ['temporary', 'editing'].indexOf($parent.orderStatus) !=
                          -1
                      "
                      :src="require('@/assets/preview_order/baianji.svg')"
                      alt=""
                    />
                  </div>
                </el-popover>

                <el-popover placement="bottom" trigger="click">
                  <div class="detailWindowBody">
                    <div
                      class="detailOpt"
                      v-for="(goodsGuiGeItem,
                      goodsGuiGeIndex) in item.pageSpecification"
                      :key="goodsGuiGeIndex"
                    >
                      <h1>{{ goodsGuiGeItem.key }}</h1>
                      <div class="optClass">
                        <span
                          v-for="(actGuige, actIndex) in goodsGuiGeItem.value"
                          :key="actIndex"
                          :title="actGuige.name"
                          @click="
                            goodsGuiGeItem.active = actIndex;
                            $parent.goodsDetailChoice(
                              item.detail[goodsGuiGeIndex],
                              actGuige,
                              item
                            );
                            $forceUpdate();
                          "
                          :class="
                            actIndex == goodsGuiGeItem.active ? 'active' : ''
                          "
                          >{{ actGuige.name }}</span
                        >
                      </div>
                    </div>
                    <!-- <div class="btnGroup">
                      <button>取消</button>
                      <button class="queRen">确认</button>
                    </div> -->
                  </div>
                  <!-- 可选择 -->
                  <div
                    slot="reference"
                    class="showDetail"
                    v-if="
                      ['temporary', 'editing'].indexOf($parent.orderStatus) !=
                        -1
                    "
                  >
                    <div class="showDetailCon">
                      <p
                        v-for="(bitem, bindex) in item.detail"
                        :key="bitem.key + bindex"
                        :title="bitem.key + ':' + bitem.value"
                        class="goodsDetailOpt ispay"
                      >
                        {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                      </p>
                    </div>

                    <img
                      @click="
                        ['temporary', 'editing'].indexOf($parent.orderStatus) !=
                        -1
                          ? $parent.openDetailWindow(item)
                          : ''
                      "
                      v-if="
                        ['temporary', 'editing'].indexOf($parent.orderStatus) !=
                          -1
                      "
                      :src="require('@/assets/preview_order/baianji.svg')"
                      alt=""
                    />
                  </div>
                </el-popover>
              </li>

              <!-- 单价 -->
              <li class="price">
                <b
                  >{{
                    $fun.RMBNumSegmentation(
                      $fun.ceil(
                        (item.confirm_price && item.confirm_price > 0
                          ? item.confirm_price
                          : item.price) * $parent.datas.exchange_rate
                      )
                    )
                  }}€</b
                >
                <span
                  >({{
                    $fun.RMBNumSegmentation(
                      item.confirm_price && item.confirm_price > 0
                        ? item.confirm_price
                        : item.price
                    )
                  }}￥)</span
                >
              </li>
              <!-- 数量 -->
              <li class="num">
                <!--2022/11/12 上午 修改订单的时候需要修改的是num,不是报价单位 -->
                <el-input-number
                  v-if="
                    $route.query.type == 'obligation' &&
                      ['editing'].indexOf($parent.orderStatus) != -1 &&
                      item.num
                  "
                  v-model="item.num"
                  step-strictly
                  @change="$parent.getTotalPrice(item)"
                  :min="1"
                  size="small"
                ></el-input-number>
                <el-input-number
                  v-else-if="
                    ['temporary', 'editing'].indexOf($parent.orderStatus) !=
                      -1 && item.confirm_num
                  "
                  v-model="item.num"
                  step-strictly
                  @change="$parent.getTotalPrice(item)"
                  :min="1"
                  size="small"
                ></el-input-number>
                <el-input-number
                  v-else-if="
                    ['temporary', 'editing'].indexOf($parent.orderStatus) !=
                      -1 && item.num
                  "
                  v-model="item.num"
                  step-strictly
                  @change="$parent.getTotalPrice(item)"
                  :min="1"
                  size="small"
                ></el-input-number>
                <span v-else>{{ item.num }}</span>
              </li>
              <!-- 调查在库数量 -->
              <li
                class="num"
                v-if="
                  [
                    'obligation',
                    'OrderDetails',
                    'purchased',
                    'purchase',
                  ].indexOf($parent.orderStatus) != -1
                "
              >
                {{ item.confirm_num }}
              </li>

              <!-- 总价 -->
              <li class="total">
                <span
                  >{{
                    $fun.RMBNumSegmentation(
                      $fun.ceil(item.totalPrice * $parent.datas.exchange_rate)
                    )
                  }}€</span
                >
                <span>({{ $fun.RMBNumSegmentation(item.totalPrice) }}￥)</span>
              </li>

              <!-- 附加服务 -->
              <li class="options">
                <div
                  v-if="
                    [
                      'obligation',
                      'purchased',
                      'offer',
                      'purchase',
                      'delete',
                    ].indexOf($parent.orderStatus) != -1 && item.option != '[]'
                  "
                  class="obligationOptionInput"
                  @click="
                    $parent.setOrderOption(item);
                    openOptionDialog(item);
                  "
                >
                  <p
                    class="optList"
                    v-for="(optionItem, index) in item.option"
                    :key="index"
                    v-show="optionItem.checked"
                  >
                    {{ optionItem.tag }}&nbsp;{{ optionItem.name_translate }}*{{
                      optionItem.num
                    }}
                  </p>
                </div>
                <div
                  class="optionInput"
                  @click="
                    $parent.setOrderOption(item);
                    openOptionDialog(item);
                  "
                  v-show="
                    ['temporary', 'editing'].indexOf($parent.orderStatus) != -1
                  "
                >
                  <div class="inputOption" v-if="item.option.length == 0">
                    <p class="placeHole">
                      <img
                        :src="require('@/assets/icon/addOption.png')"
                        alt=""
                      />
                      {{ $fanyi("追加") }}
                    </p>
                  </div>
                  <div v-else class="inputOption haveData">
                    <p
                      v-show="
                        !!(typeof item.option != 'string') &&
                          eeitem.checked == true
                      "
                      class="list"
                      v-for="(eeitem, index) in item.option"
                      :key="index"
                    >
                      {{ eeitem.tag }}&nbsp;{{ eeitem.name_translate }}*{{
                        eeitem.num
                      }}
                    </p>
                  </div>
                </div>
              </li>
              <!-- 备注 -->
              <li class="remark">
                <div
                  class="showSpan"
                  v-if="
                    ['temporary', 'editing'].indexOf($parent.orderStatus) == -1
                  "
                >
                  <span>{{ item.client_remark }}</span>
                </div>

                <textarea
                  v-if="
                    ['temporary', 'editing'].indexOf($parent.orderStatus) != -1
                  "
                  v-model="item.client_remark"
                  :placeholder="$fanyi('您可以在这边添加商品备注')"
                ></textarea>
              </li>
              <!-- 费用明细 -->
              <!-- v-if="
                    ['obligation', 'purchased', 'purchase'].indexOf(
                      $parent.orderStatus
                    ) != -1
                  " -->
              <li
                class="constDetail"
                v-if="
                  ['obligation', 'purchased', 'purchase'].indexOf(
                    $parent.orderStatus
                  ) != -1
                "
              >
                <button @click="openCostBreakdown(item.id, item)">
                  {{ $fanyi("看到细节")
                  }}<img
                    :src="require('@/assets/icon/arrow-right.png')"
                    alt=""
                  />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        class="showAllGoods"
        :class="{ openBtn: $parent.showAllGoods }"
        v-if="$parent.numberOfStores.goods > 1"
      >
        <span @click="$parent.showAllGoods = !$parent.showAllGoods">
          {{ $fanyi("部署") }}
        </span>
        <img
          class="jiahaoIcon"
          v-if="!$parent.showAllGoods"
          :src="require('@/assets/icon/jiahao_fill.svg')"
          @click="$parent.showAllGoods = !$parent.showAllGoods"
        />
        <img
          class="jiahaoIcon"
          v-else
          :src="require('@/assets/icon/jianhao.svg')"
          @click="$parent.showAllGoods = !$parent.showAllGoods"
        />
      </div>
    </div>
    <optionDialog ref="OptionDialog" />
  </div>
</template>
<script>
import optionDialog from "./optionDialog.vue";
export default {
  data() {
    return {};
  },
  components: {
    optionDialog,
  },
  computed: {},
  created() {},
  methods: {
    openOptionDialog(item) {
      this.$refs.OptionDialog.open(item);
    },
    closeOptionDia(v) {
      this.$refs.popoveraite[v].doClose();
    },
    // 设置不能为0
    setNumber(neow, optionListitem) {
      if (neow == undefined) {
        optionListitem.num = 1;
        this.$forceUpdate();
      }
    },
  },
};
</script>
<style lang="scss">
.el-popover.el-popper.dditionalServicesPro {
  background-color: transparent;
  box-shadow: none;
  border: none;

  .popper__arrow {
    display: none;
  }
}

button {
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
@import "@/css/mixin.scss";

.orderPage {
  background-color: #f6f6f6;
}

.main.zhu {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 29px 19px 0px;
  background: #ffffff;
  border: solid 1px #e3e3e3;
  border-radius: 6px;
  // height: 482px;
  // min-height: 482px;
  // overflow: hidden;
  position: relative;

  .neirong {
    height: 300px;
    overflow: hidden;
  }

  &.AllGoods {
    height: auto !important;
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;

    span {
      font-size: 16px;
      font-weight: 400;
      color: #2f2f2f;
      line-height: 16px;

      .headT1 {
        display: block;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 600;
      }

      .orderSnBox {
        font-size: 13px;
      }

      .dateBox {
        font-size: 13px;
      }
    }

    .editOrder {
      padding: 0 25px;
      background: $homePageSubjectColor;
      border-radius: 16px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      margin-left: 21px;
      background: #1a289d;
      border-radius: 3px;

      img {
        height: 13px;
        margin-right: 5px;
        width: 13px;
      }

      span {
        color: white;
        font-size: 14px;
      }
    }
  }

  .order {
    &.orderfixedBox {
      padding-top: 56px;
    }

    // 同时设置表头和内容宽度
    ul {
      display: flex;
    }

    li {
      flex: 1;
      text-align: center;

      // 全选 表头和内容框
      &.inputBox {
        flex: 1.4;
        display: flex;
        align-items: center;

        .headCheckBox {
          margin-top: 0;
        }

        > .rowNumber {
          width: 60px;
          display: inline-block;
        }

        > * {
          margin-top: -12px;
        }
        .zhanWeiCheckBox {
          width: 16px;
          height: 16px;
          margin-left: 20px;
        }
      }

      // 产品
      &.goods {
        flex: 0.9;
        padding: 20px;
        box-sizing: border-box;

        text-overflow: -o-ellipsis-lastline;

        .showGoodsTitle {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }

      // 详细
      &.detail {
        width: 160px;
        padding: 0 12px 0;

        &::-webkit-scrollbar {
          width: 3px;
          height: 1px;
        }

        &::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 10px;
          -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
          background: #535353;
        }

        &::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
          border-radius: 10px;
        }
      }

      // 单价
      &.price {
        width: 160px;
      }

      // 数量
      &:nth-child(5) {
        width: 140px;
      }

      // 总价
      &.total {
        width: 80px;
      }

      // 附加服务
      &.options {
      }

      // 备注
      &.remark {
        width: 200px;
      }

      &.zhongGuoSuoYouFeiYong {
        flex: 0 0 140px;
        margin-right: 10px;

        &.head {
          > div {
            display: inline;
            width: 128px;
            line-height: 14px;
          }
        }

        &.body {
          padding: 20px 0;

          > div {
            display: block;
            width: 100%;
            height: 100%;
            color: #000000;

            .pricePerUnit {
              margin-bottom: 8px;

              span {
                color: #000000;
                font-size: 12px;
                font-weight: 500;
                line-height: 15px;
              }
            }

            .mingXi {
              display: block;
              width: 140px;
              height: 40px;
              background: rgba(176, 227, 242, 0.2);
              border: 1px solid #009fce;
              border-radius: 3.9px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              font-size: 13px;
              font-weight: 500;
              color: #2b2b2b;

              .arrow {
                width: 10px;
                height: 6px;
                margin-left: 4px;
              }
            }
          }
        }
      }

      &.yuGuDaoShouFeiYong {
        flex: 0 0 140px;

        &.head {
        }

        &.body {
          padding: 20px 0;

          > div {
            width: 100%;
            height: 100%;
            color: #000000;

            .pricePerUnit {
              margin-bottom: 8px;

              span {
                color: #000000;
                font-size: 12px;
                font-weight: 500;
                line-height: 15px;
              }
            }

            .mingXi {
              height: 40px;
              background: rgba(176, 242, 226, 0.2);
              border: 1px solid #00bf99;
              border-radius: 3.9px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              font-size: 13px;
              font-weight: 500;
              color: #2b2b2b;

              .arrow {
                width: 10px;
                height: 6px;
                margin-left: 4px;
              }
            }
          }
        }
      }

      &.constDetail {
        flex: 0 0 150px;
        margin-right: 10px;
        font-size: 12px;
        font-weight: 500;
        color: #1a289d;
        line-height: 14px;
      }

      &.replacementItemButton {
        flex: 0 0 450px;
        padding-top: 0 !important;
        align-items: center !important;

        .operationButton {
          width: 170px;
          height: 40px;
          font-size: 13px;
          line-height: 40px;
          border-radius: 6px;
          margin-right: 15px;

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            opacity: 0.7;
          }

          border: 1px solid #5b80fa;

          &.checkTheGoods {
            font-weight: 500;
            color: #5b80fa;
          }

          &.commodityReplacement {
            background: #5b80fa;
            color: white;
          }
        }
      }
    }

    // 内容里的ul样式
    .goodsBox {
      overflow: hidden;

      > div {
        margin-top: 10px;
        border-bottom: solid 9px transparent;

        // position: relative;
        &:after {
          // position: absolute;
          left: -20px;
          bottom: -9px;
          content: "";
          background-color: #f1f1f1;
          width: 1400px;
          height: 9px;
        }

        > h1 {
          display: flex;
          align-items: center;
          height: 56px;

          .checkedBox {
            // width: 55px;
            height: 100%;
            display: flex;
            align-items: center;

            > input {
              margin-right: 16px;
              margin-left: 20px;
            }
          }
        }

        > .goodsCon {
          .goodsDetailCon,
          > ul {
            height: 120px;
            border-top: solid 1px #ededed;
            margin-bottom: 8px;

            > li {
              line-height: 20px;
              display: flex;
              align-items: flex-start;
              padding-top: 15px;
              justify-content: center;
              flex-wrap: wrap;

              // 全选 内容框
              &.inputBox {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                input {
                  margin-left: 20px;
                }

                img {
                  width: 80px;
                  height: 80px;
                  margin-left: 10px;
                  cursor: pointer;
                }
              }

              // 产品
              &.goods {
                cursor: pointer;

                text-align: left;
              }

              // 详细
              &.detail {
                cursor: pointer;
                position: relative;
                box-sizing: border-box;
                text-align: left;

                &.active {
                  background-color: white;
                }

                > div {
                  width: 100%;
                }

                img {
                  width: 20px;
                  height: 20px;
                  position: absolute;
                  top: 20px;
                  right: 28px;
                }
              }

              // 总价
              &.total {
                font-size: 14px;
                color: #ffa724;
                line-height: 19px;
              }

              // 附加服务
              &.options {
                flex: 0 0 167px;

                .optList {
                  color: black;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 167px;
                }

                .obligationOptionInput {
                  cursor: pointer;
                  background-color: transparent;
                  border: none;
                  overflow-y: auto;
                  height: 90px;
                  text-align: left;
                }
                .optionInput {
                  // height: 80px;
                  // max-height: 80px;
                  border-radius: 4px;

                  position: relative;

                  .list {
                    text-align: left;
                    padding-left: 10px;
                  }

                  cursor: pointer;

                  .inputOption {
                    width: 100%;
                    height: 90px;
                    // max-height: 80px;
                    overflow-y: auto;

                    &.haveData {
                      background-color: white;
                      border: 1px solid #ededed;
                      color: #a7a7a7;
                    }

                    .placeHole {
                      width: 150px;
                      height: 38px;
                      border: 1px solid #4f6fff;
                      border-radius: 3px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      padding: 10px;
                      font-size: 14px;
                      color: #4f6fff;
                      img {
                        width: 15px;
                        height: 15px;
                        margin-right: 8px;
                      }
                    }

                    .placeTiShi {
                      color: #a7a7a7;
                      font-size: 11px;

                      text-align: left;
                    }
                  }
                }
              }

              // 备注
              &.remark {
                .showSpan {
                  height: 120px;
                  overflow: auto;
                }
                > textarea {
                  width: 90%;
                  border: 1px solid #e4e7ed;
                  height: 90px;
                  border-radius: 4px;
                  font-size: 12px;
                  line-height: 25px;

                  padding-left: 10px;

                  &::placeholder {
                    color: #a7a7a7;
                    text-align: left;
                    padding: 10px;
                    padding-left: 5px;
                    font-size: 12px;
                    line-height: 18px;
                  }
                }
              }

              &.constDetail {
                margin-top: 28px;

                button {
                  font-size: 14px;
                  line-height: 19px;
                  padding: 0 19.5px;
                  height: 40px;
                  background: rgba(255, 255, 255, 0.2);
                  border: 1px solid #1a289d;
                  border-radius: 3.9px;
                  font-size: 12px;
                  font-weight: 500;
                  color: #1a289d;
                  line-height: 20px;

                  img {
                    width: 6.5px;
                    height: 10.4px;
                    margin-left: 6.5px;
                  }
                }
              }
            }
          }

          .blueStatusUl {
            background: #f3f4ff;
            border: 1px solid #5b80fa;
            border-radius: 4px;
          }

          .questionReStatusUl {
            border-radius: 4px;
            background: #fcecef;
            border: 1px solid #ea6389;
          }
        }
      }
    }

    // 设置表头
    > ul {
      background-color: #f0f0f0;
      height: 56px;
      line-height: 32px;
      z-index: 5;
      position: sticky;
      top: 104px;

      &.fixedBox {
        position: fixed;
        top: 168px;
        width: 1360px;
      }

      &.shang {
        top: 104px;
      }

      > li {
        font-size: 12px;
        line-height: 14px;
        display: flex;
        font-weight: 600;
        height: 56px;
        align-items: center;
        justify-content: center;

        &:first-child {
          justify-content: flex-start;
        }
      }

      &:hover {
        background-color: #f0f0f0;
      }
    }

    ul {
      display: flex;
      line-height: 70px;
      font-size: 12px;

      input {
        margin-left: 20px;
      }

      .pic {
        position: relative;

        .upload-image {
          position: absolute;
          top: 0;
          left: 60px;

          .upload-demo {
            position: absolute;
            top: 12px;
            left: 0;
            width: 30px;
            height: 28px;
            // opacity: 0;
          }
        }
      }

      .img {
        position: relative;
        width: 40px;
        height: 50px;
        z-index: 1;

        &:hover .bigImg {
          display: block;
        }

        img {
          width: 40px;
          height: 50px;
          margin-top: 10px;
        }

        .bigImg {
          display: none;
          position: absolute;
          top: 60px;
          left: 50px;

          img {
            margin: 0;
            width: 300px;
            height: 300px;
          }
        }
      }

      .num {
        input {
          width: 50px;
        }
      }

      .beikao {
        textarea {
          margin-top: 3%;
          width: 85%;
          height: 70%;
          padding: 5px;
          resize: none;
          border: 1px solid #cccc;
        }
      }

      .option {
        position: relative;
        overflow: hidden;
        height: 100%;

        p {
          width: 80%;
          line-height: 23px;
          text-align: left;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;

          .optionNum {
            margin-left: 5px;
            color: #5bb578;
          }
        }
      }

      i {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 25px;
        color: #666;
        z-index: 99;
        cursor: pointer;
      }
    }
  }

  .showAllGoods {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 48px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 4px 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9;

    span {
      font-size: 14px;
      font-weight: 400;
      color: #1a289d;
      margin-right: 10px;
      cursor: pointer;
    }

    &.openBtn {
      position: sticky;
      bottom: 101px;
      width: 1400px;
      margin-left: -20px;
      left: 0;
      right: 0;
    }

    img {
      width: 24px;
      height: 24px;
      margin-right: 24px;
      cursor: pointer;
    }
  }
}

// 订单详情状态页面
.dingDanXiangQing {
  .xiangqingCon {
    position: relative;
  }

  .haveReplacementLink {
    width: 162px;
    height: 17px;
    background: #5b80fa;
    border-radius: 6px 0px 6px 0px;
    position: absolute;
    line-height: 17px;
    text-align: center;
    font-size: 12px;
    color: #fffefe;
    margin-left: -2px;
    margin-top: -1px;

    &.noReplacementLink {
      background: #f95262;
    }
  }

  .goodsDetailOrder {
    padding-left: 20px;

    .optionsBox {
      margin-bottom: 10px;
      display: flex;
      align-items: flex-start;

      .optionsTitle {
        font-size: 14px;
        color: #666666;
        margin-right: 10px;
      }

      .allOptions {
        font-size: 14px;
        line-height: 20px;
        overflow: hidden;

        .tag {
          position: relative;
          bottom: 2px;
        }
      }

      .lookAllOptions {
        margin-left: 10px;
        font-size: 14px;
        color: #888888;
        cursor: pointer;
      }
    }

    .remarkCon {
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      margin-bottom: 10px;

      .remarkTitle {
        color: #666666;
      }

      .remarkBody {
        overflow: auto;
      }
    }

    .huiFu {
      margin-bottom: 10px;
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      margin-bottom: 10px;

      .huiTitle {
        color: #666666;
      }

      .huiBody {
      }
    }
  }
}
.optionPrement {
  padding: 18px;
  margin: 0 !important;
  width: 310px;
  h3 {
    text-align: left;
    font-size: 18px;
    line-height: 22.84px;
    font-weight: SemiBold;
    color: #2f2f2f !important;
    margin-bottom: 20px;
  }
  p {
    text-align: left;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 22px;
    color: black !important;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
// 附加服务选框样式
.additionalServices {
  width: 940px;
  border-radius: 10px;
  border: 1px solid rgba($color: $homePageSubjectColor, $alpha: 0.3);
  // width: 900px;
  background-color: white;
  max-height: unset;
  h4 {
    padding: 30px 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 18px;
      font-weight: 400;
    }
    img {
      width: 10px;
      cursor: pointer;
      height: 10px;
      transform: translateY(-10px);
    }
  }
  .optionListBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 45px;
    .optionOpt {
      width: 417px;
      height: 89px;
      background: #ffffff;
      border: 1px solid #e9e9e9;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 15px;
      margin-bottom: 13px;
      .headBox {
        height: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 15px;
        .optionCheckInput {
          border: 1px solid #202a93;
          border-radius: 3px;
          margin-right: 9px;
        }
        img {
          margin-left: 6px;
        }
      }
      .conBox {
        display: flex;
        justify-content: center;
        align-items: center;

        .conOpt {
          flex: 1;
          li {
            color: #282828;
          }
          .title {
            zoom: 0.58;
            font-size: 20px;
            line-height: 30px;
          }
          .price {
            font-size: 12.22px;
          }
        }
        .conOpt.zongJia {
          text-align: right;
          padding-right: 10px;
        }
        /deep/.el-input-number {
          margin-right: 40px;
        }
      }
    }
  }

  .foot {
    text-align: right;

    > p {
      font-size: 12px;

      font-weight: bold;
      color: #000000;
      line-height: 16px;

      font {
        color: #ffa724;
      }

      &:first-child {
        margin-bottom: 12px;
      }

      button {
        font-size: 14px;

        color: #000000;
        line-height: 20px;
        background-color: transparent;
        border: solid 1px #47bd9b;
        border-radius: 5px;
        padding: 5px 12px;
        outline: none;
        color: #47bd9b;

        &.react {
          margin-right: 20px;
        }

        &.save {
          background-color: #47bd9b;
          color: $homePageSubjectColor;
          color: white;
        }
      }
    }
  }
}

.operatingOptions {
  width: $pageWidth;
  position: sticky;
  margin: 20px auto;
  right: 0;
  left: 0;
  z-index: 10;
  bottom: 0px;
  border: 1px solid #e8e8e8;
  // height: 106px;
  background: #ffffff;

  border-radius: 6px;

  display: flex;
  padding: 10px;
  justify-content: space-between;

  .huiLv {
    font-size: 10px;
    color: #414141;
    line-height: 19px;
    transform: scale(0.83);
    position: relative;
    top: 1px;
    left: 10px;
  }

  .shiYongYouHuiQuan {
    .tiShi {
      font-size: 13px;
      line-height: 19px;
      color: #888888;
    }

    .userYouHuiQuan {
      margin-left: 6.5px;
      background-color: transparent;
    }
  }

  .price {
    font-size: 21px;
    font-weight: bold;
    color: #ffa724;
    line-height: 28px;
    margin-right: 5px;
  }

  .choies {
    .caoZuoCaiDan {
      font-size: 14px;
      line-height: 19px;
      color: $homePageSubjectColor;
      padding: 10px 20px;
      border-radius: 4px;
      cursor: pointer;
    }

    .delBtn {
      margin-right: 20px;
    }

    .qx {
      margin-right: 6px;
    }

    // 加入商品库按钮
    .addLibraryOfGoods {
      height: 19px;
      font-size: 14px;
      color: #00187c !important;
      line-height: 16px;
      letter-spacing: unset;

      .addIcon {
        display: inline-block;
        width: 21.45px;
        height: 21.45px;
        margin-left: 6px;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #dcdfe6;
      }
    }
  }

  > div {
    display: flex;
    align-items: center;

    &:first-child {
      color: #888888;
      font-size: 14px;
      margin-right: 15px;

      input {
        margin-left: 33px;
        margin-right: 6.6px;
      }

      > button {
        font-size: 14px;

        color: #888888;
        line-height: 19px;

        background-color: transparent;

        &:first-child,
        &:nth-child(2) {
          color: #888888;
        }

        &.addgoods {
          color: #3a4de4;
          margin: 0 20px 0 40px;
        }

        &:nth-child(5) {
          color: #ffa724;
        }
      }
    }

    &.shop {
      flex: 1;

      > p {
        flex: 1;
        font-size: 14px;
        color: #000000;
        line-height: 19px;

        &.shopGr {
          flex: 0 0 200px;
          margin-left: auto;

          span {
            white-space: nowrap;
          }
        }

        &.prices {
          text-align: right;
          display: flex;
          flex: 0;
          flex-direction: column;

          span {
            &.totalAmount {
              font-size: 17px;
              .priceBox {
                margin-left: 10px;
              }
            }
            &.priceEURBox {
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }

            line-height: 20px;
            justify-content: flex-end;

            // margin
            .renMingBi {
              margin-left: -3px;
            }

            .priceBox {
              white-space: nowrap;
              margin-left: 5px;
              // margin-right: 5px;
            }
          }
        }

        &.amountPaid {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .amountPaidPrice {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-left: 20px;

            .amountPaidPriceTit {
              font-size: 14.52px;
              line-height: 65px;
              height: 65px;
              font-weight: 500;
              margin-right: 5.94px;
            }

            .amountPaidPricCon {
              display: inline-flex;
              align-items: flex-end;
              flex-direction: column;

              .ouYuan {
                color: #ffa200;
                font-size: 19.8px;
                font-weight: bold;
              }

              .renMingBi {
                font-size: 12px;
                font-weight: 400;
                color: #9c9c9c;
              }
            }
          }
        }

        > span {
          line-height: 20px;
          margin: auto 0;
          display: block;
          display: flex;
          align-items: center;
        }

        .tiJi {
          margin-left: 40px;
        }
      }
    }

    &.buttonGroup {
      .btnDiv {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-right: 10px;
        margin-left: 65px;
        img {
          width: 21px;
          height: 18px;
          margin-bottom: 5px;
        }
      }
      > button {
        width: 250px;
        height: 60px;
        border-radius: 4px;
        border: 1px solid #47bd9b;
        font-size: 16px;
        font-weight: bold;
        color: #47bd9b;
        background: white;
        line-height: 24px;
        padding: 0 20px;
        margin: 10px 10px;
        font-family: "Open Sans", Sans-serif;

        &.payBtn {
          color: #ffffff;
          background: #47bd9b;
        }

        &.chongXinTiChu {
          color: #ffffff;
          background: #47bd9b;
        }

        &:nth-child(2) {
          color: #ffffff;
          background: #47bd9b;
        }
      }
    }
  }
}

.theFullCost {
  display: flex;
  width: $pageWidth;
  background: rgba($color: #3a4de4, $alpha: 0.05);
  border-radius: 6px;
  border: solid 1px #e8e8e8;
  margin: 0 auto 20px;

  .fujiafuwu {
    position: relative;
  }

  > div {
    padding: 20px;

    > h2 {
      font-size: 14px;
      font-weight: 600;
      color: #000000;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    &.Con {
      width: 420px;
      font-size: 12px;

      color: #000000;
      line-height: 16px;

      > p {
        line-height: 32px;
        display: flex;
        width: 420px;

        span {
          display: inline-block;

          &:first-child {
            width: 154px;
            margin-right: 20px;
          }

          &:nth-child(2) {
            margin-right: 5px;
          }

          &.spanBody {
            width: 180px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &.fujia {
            max-width: 120px;
          }
        }

        .detail {
          font-size: 12px;
          background-color: transparent;
          border: none;
          cursor: pointer;
          outline: none;
          color: #1e2997;
          line-height: 16px;
          margin-left: 10px;
        }
      }
    }

    &.wuliufangshi {
      margin-right: 20px;

      .transportation {
        width: 360px;
        height: 30px;
        border-radius: 6px;
        border: 1px solid #b6bef7;
        margin-bottom: 10px;
        padding-left: 10px;
        background-color: white;
        position: relative;
        padding-left: 10px;
        line-height: 30px;
        position: relative;

        .transportationDown {
          position: absolute;
          top: 50%;
          transform: translate(0%, -50%) rotate(0deg);
          transition: 0.3s;
          right: 10px;
        }

        .transportationOpt {
          position: absolute;
          top: 102%;
          left: 0;
          width: 360px;
          height: 40px;
          border-radius: 10px;
          background-color: white;
          border: 1px solid rgba($color: #182280, $alpha: 0.3);
          padding: 5px 0;
          overflow: hidden;

          p {
            width: 100%;
            height: 26px;
            padding-left: 30px;
            display: flex;
            align-items: center;

            img {
              position: absolute;
              left: 13px;
            }

            &:hover {
              background-color: #e2e5ff;
              color: $homePageSubjectColor;
            }
          }
        }
      }

      > select {
        width: 180px;
        height: 30px;
        border-radius: 15px;
        border: 1px solid #b6bef7;
        margin-bottom: 10px;
        padding-left: 10px;
      }

      > span {
        display: block;
        width: 180px;
        font-size: 12px;

        color: #888888;
        line-height: 16px;
      }
    }

    &.teShuBeiZhu {
      flex: 1;

      > h2 {
        margin-top: 10px;
        line-height: unset;
      }

      > textarea {
        width: 100%;
        height: 120px;

        border-radius: 4px;
        border: solid 1px #e4e7ed;

        margin-bottom: 5px;
        padding-left: 10px;
        padding-top: 7px;
        background-color: white;
      }

      > span {
        font-size: 12px;

        color: #888888;
        line-height: 16px;
      }
    }
  }
}

.addressAdministration {
  margin: 0 auto;
  width: $pageWidth;
  background: #ffffff;
  border-radius: 6px;
  margin-bottom: 20px;
  display: flex;

  padding: 30px;

  .optOne {
    width: 314px;

    &:first-child {
      margin-right: 236px;
    }

    > p {
      display: flex;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &.oheader {
        justify-content: space-between;
        margin-bottom: 20px;

        .oselect {
          /deep/.el-input {
            width: 180px;
            height: 30px;
            // border: 1px solid rgba($color: $homePageSubjectColor, $alpha: 0.3);
          }

          /deep/.el-input__inner {
            font-size: 12px;

            color: #000000;
            line-height: 16px;
            border-radius: 6px;
            height: 100%;
            border: 1px solid rgba($color: $homePageSubjectColor, $alpha: 0.3);
          }

          /deep/.el-select__caret {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      > span {
        display: flex;
        align-items: center;

        &:first-child {
          width: 64px;
          margin-right: 10px;
          font-size: 12px;
        }
      }

      > input {
        width: 240px;
        height: 22px;
        background: #f6f6f6;
        border-radius: 5px;
        border: 1px solid #d7d7d7;
        padding-left: 10px;
      }
    }
  }
}

.checkOptionBox {
  margin-right: 3px;
}

/deep/.el-checkbox__input {
  height: 16px;
}

.caoZuoAnNiu {
  button {
    font-size: 14px;
    transition: 0.3s;
    text-align: left;
    height: 34px;
    line-height: 34px;
    width: 100%;

    &:hover {
      color: #1e2997;

      background-color: rgba($color: #1e2997, $alpha: 0.034);
    }
  }
}

.yuGuDaoShouFeiYongMingXi {
  width: 100%;

  p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      width: 210px;
      display: inline-block;
      margin-right: 10px;
    }

    .body {
      display: inline-block;
      color: #ffa724;
      font-size: 12px;
    }
  }

  &.yugu {
    p {
      .title {
        width: 271px !important;
      }
    }
  }
}

.detailWindowBody {
  width: 420px;
  border-radius: 10px;
  background-color: white;
  padding: 20px 10px 20px 20px;
  max-height: 500px;
  overflow-y: auto;

  .detailOpt {
    h1 {
      font-size: 12px;

      line-height: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .optClass {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;

      span {
        display: block;
        padding: 7px 10px;
        border: 1px solid #cccccc;
        font-size: 12px;
        line-height: 16px;

        margin-right: 10px;
        cursor: pointer;
        margin-bottom: 10px;
        max-width: 115px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.active {
          color: #ffa724;
          border: 1px solid #ffa724;
        }
      }
    }
  }

  .btnGroup {
    text-align: right;

    button {
      font-size: 14px;

      color: #000000;
      line-height: 20px;
      background-color: transparent;

      &.queRen {
        margin-left: 20px;
        color: $homePageSubjectColor;
        text-decoration: underline;
      }
    }
  }
}

.showDetail {
  height: 80px;
  margin-right: 30px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
    background: #ededed;
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .showDetailCon {
    width: 105px;
    overflow: hidden;

    .goodsDetailOpt {
      width: 100px;
    }
  }
}

.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;

  .goodsDetailAllOneBox {
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    p {
      margin-left: 20px;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
}

.showfba {
  display: block;
  cursor: pointer;
  height: 13px;
  line-height: 13px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;

  .goodsDetailAllOneBox {
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    p {
      margin-left: 20px;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
}

.priceDetail {
  min-height: 74px;
  background-color: white;
  z-index: 2;

  > ul {
    display: flex;
    border-radius: 10px;

    > li {
      height: 37px;
      background: #fafafa;
      width: 80px;
      text-align: center;
      height: 37px;
      line-height: 37px;
      border: solid 1px #ededed;
      margin: 0 -1px -1px 0;
      font-size: 12px;
      color: #000000;

      &.optionName {
        width: 240px;
      }
    }

    &.obody {
      li {
        background-color: white;

        &.optionName {
          background: #fafafa;
          width: 240px;
        }
      }
    }
  }
}

.couponDetail {
  color: #1e2997;
}
</style>
<style lang="scss">
// 单番商品预估费用明细
.breakdownOfExpenses {
  margin: 0 !important;
  .popper__arrow {
    display: none !important;
  }
}
</style>
