<template>
  <div>
    <div>
      <el-dialog
        :title="$fanyi('导入的详细信息')"
        :visible.sync="$parent.showCostBreakdown"
        :close-on-click-modal="false"
        width="550px"
      >
        <div>
          <div class="productInformation">
            <div class="productInformationTitle alignBothEnds">
              <span class="titleName">{{ $fanyi("立方米体积") }}:</span>
              <span class="titleValue"
                >{{ $fun.ceil(goodsAct.volume_m3) }}m³</span
              >
            </div>
            <div class="productInformationBody">
              <div class="informationOpt alignBothEnds">
                <div class="optName">{{ $fanyi("产品价值") }}:</div>
                <div class="optPrice">
                  <span
                    ><b
                      >{{
                        $fun.EURNumSegmentation(
                          goodsAct.china_amount.list.goods_amount
                        )
                      }}€</b
                    >
                  </span>
                  <span>
                    ({{
                      $fun.RMBNumSegmentation(
                        Math.ceil(
                          (goodsAct.china_amount.list.goods_amount /
                            datas.exchange_rate) *
                            100
                        ) / 100
                      )
                    }}<b>￥</b>)</span
                  >
                </div>
              </div>
              <div class="informationOpt alignBothEnds">
                <div class="optName">
                  <label for=""
                    >{{ $fanyi("手续费") }} ({{
                      Math.round(datas.service_rate * 100)
                    }}%):</label
                  >
                </div>
                <div class="optPrice">
                  <span
                    ><b
                      >{{
                        $fun.EURNumSegmentation(
                          goodsAct.china_amount.list.service_amount
                        )
                      }}€</b
                    >
                  </span>
                  <span>
                    ({{
                      $fun.RMBNumSegmentation(
                        Math.ceil(
                          (goodsAct.china_amount.list.service_amount /
                            datas.exchange_rate) *
                            100
                        ) / 100
                      )
                    }}<b>￥</b>)</span
                  >
                </div>
              </div>
              <div class="informationOpt alignBothEnds">
                <div class="optName">
                  <label for="">{{ $fanyi("option说明界面") }}:</label>
                </div>
                <div class="optPrice">
                  <span
                    ><b
                      >{{
                        $fun.EURNumSegmentation(
                          goodsAct.china_amount.list.option_amount
                        )
                      }}€</b
                    >
                  </span>
                  <span>
                    ({{
                      $fun.RMBNumSegmentation(
                        Math.ceil(
                          (goodsAct.china_amount.list.option_amount /
                            datas.exchange_rate) *
                            100
                        ) / 100
                      )
                    }}<b>￥</b>)</span
                  >
                </div>
              </div>
              <div class="informationOpt alignBothEnds">
                <div class="optName">
                  <label for="">{{ $fanyi("中国物流") }}:</label>
                </div>
                <div class="optPrice">
                  <span
                    ><b
                      >{{
                        $fun.EURNumSegmentation(
                          goodsAct.china_amount.list.china_freight_amount
                        )
                      }}€</b
                    >
                  </span>
                  <span>
                    ({{
                      $fun.RMBNumSegmentation(
                        Math.ceil(
                          (goodsAct.china_amount.list.china_freight_amount /
                            datas.exchange_rate) *
                            100
                        ) / 100
                      )
                    }}<b>￥</b>)</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="productInformation">
            <div
              class="productInformationTitle alignBothEnds"
              style="background: #eff9fc"
            >
              <span class="titleName"
                >{{ $fanyi("不含运费的产品总成本") }}:</span
              >
              <span class="titleValue">
                <span
                  ><b
                    >{{
                      $fun.EURNumSegmentation(goodsAct.china_amount.sum_amount)
                    }}€</b
                  >
                </span>
                <span>
                  ({{
                    $fun.RMBNumSegmentation(
                      Math.ceil(
                        (goodsAct.china_amount.sum_amount /
                          datas.exchange_rate) *
                          100
                      ) / 100
                    )
                  }}<b>￥</b>)</span
                >
              </span>
            </div>
            <div class="productInformationBody">
              <div class="informationOpt alignBothEnds">
                <div class="optName">{{ $fanyi("产品成本") }}:</div>
                <div class="optPrice">
                  <span
                    ><b
                      >{{
                        $fun.EURNumSegmentation(
                          goodsAct.china_amount.list.goods_amount
                        )
                      }}€</b
                    >
                  </span>
                  <span>
                    ({{
                      $fun.RMBNumSegmentation(
                        Math.ceil(
                          (goodsAct.china_amount.list.goods_amount /
                            datas.exchange_rate) *
                            100
                        ) / 100
                      )
                    }}<b>￥</b>)</span
                  >
                </div>
              </div>
              <div class="informationOpt alignBothEnds">
                <div class="optName">
                  <label for="">{{ $fanyi("国际运输") }}:</label>
                </div>
                <div class="optPrice">
                  <span
                    ><b
                      >{{
                        $fun.EURNumSegmentation(
                          goodsAct.client_buy_amount[xiBanYaYunFei(goodsAct)]
                            .list.international_freight_amount
                        )
                      }}€</b
                    >
                  </span>
                  <span>
                    ({{
                      $fun.RMBNumSegmentation(
                        Math.ceil(
                          (goodsAct.client_buy_amount[xiBanYaYunFei(goodsAct)]
                            .list.international_freight_amount /
                            datas.exchange_rate) *
                            100
                        ) / 100
                      )
                    }}<b>￥</b>)</span
                  >
                </div>
              </div>
              <div class="informationOpt alignBothEnds">
                <div class="optName">
                  <label for="">{{ $fanyi("西班牙的运费") }}:</label>
                </div>
                <div class="optPrice">
                  <span
                    ><b
                      >{{
                        $fun.EURNumSegmentation(
                          goodsAct.client_buy_amount[xiBanYaYunFei(goodsAct)]
                            .list.client_internal_amount
                        )
                      }}€</b
                    >
                  </span>
                  <span>
                    ({{
                      $fun.RMBNumSegmentation(
                        Math.ceil(
                          (goodsAct.client_buy_amount[xiBanYaYunFei(goodsAct)]
                            .list.client_internal_amount /
                            datas.exchange_rate) *
                            100
                        ) / 100
                      )
                    }}<b>￥</b>)</span
                  >
                </div>
              </div>
              <div class="informationOpt alignBothEnds">
                <div class="optName">
                  <label for=""
                    >{{ $fanyi("反倾销关税") }}({{
                      goodsAct.client_buy_amount[xiBanYaYunFei(goodsAct)].list
                        .anti_dumping_duty_amount_rate
                    }}):</label
                  >
                </div>
                <div class="optPrice">
                  <span
                    ><b
                      >{{
                        $fun.EURNumSegmentation(
                          goodsAct.client_buy_amount[xiBanYaYunFei(goodsAct)]
                            .list.anti_dumping_duty_amount
                        )
                      }}€</b
                    >
                  </span>
                  <span>
                    ({{
                      $fun.RMBNumSegmentation(
                        Math.ceil(
                          (goodsAct.client_buy_amount[xiBanYaYunFei(goodsAct)]
                            .list.anti_dumping_duty_amount /
                            datas.exchange_rate) *
                            100
                        ) / 100
                      )
                    }}<b>￥</b>)</span
                  >
                </div>
              </div>
              <div class="informationOpt alignBothEnds">
                <div class="optName">
                  <label for=""
                    >{{ $fanyi("增值税") }}({{
                      goodsAct.client_buy_amount[xiBanYaYunFei(goodsAct)].list
                        .added_value_tax_amount_rate
                    }}):</label
                  >
                </div>
                <div class="optPrice">
                  <span
                    ><b
                      >{{
                        $fun.EURNumSegmentation(
                          goodsAct.client_buy_amount[xiBanYaYunFei(goodsAct)]
                            .list.added_value_tax_amount
                        )
                      }}€</b
                    >
                  </span>
                  <span>
                    ({{
                      $fun.RMBNumSegmentation(
                        Math.ceil(
                          (goodsAct.client_buy_amount[xiBanYaYunFei(goodsAct)]
                            .list.added_value_tax_amount /
                            datas.exchange_rate) *
                            100
                        ) / 100
                      )
                    }}<b>￥</b>)</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="productInformation">
            <div
              class="productInformationTitle alignBothEnds"
              style="background: #effcf9"
            >
              <span class="titleName">{{ $fanyi("运输产品的总成本") }}:</span>
              <span class="titleValue">
                <span
                  ><b>
                    {{
                      $fun.EURNumSegmentation(
                        goodsAct.client_buy_amount[xiBanYaYunFei(goodsAct)]
                          .sum_amount
                      )
                    }}€</b
                  >
                </span>
                <span>
                  ({{
                    $fun.RMBNumSegmentation(
                      Math.ceil(
                        (goodsAct.client_buy_amount[xiBanYaYunFei(goodsAct)]
                          .sum_amount /
                          datas.exchange_rate) *
                          100
                      ) / 100
                    )
                  }}<b>￥</b>)</span
                >
              </span>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      datas: {},
      CostBreakdownList: [],
      detailWin: false,
    };
  },
  created() {
    this.getData();
  },
  computed: {
    xiBanYaYunFei() {
      return this.$parent.xiBanYaYunFei;
    },

    goodsAct() {
      this.$parent.goodsAct.option;
      for (let i = 0; i < this.$parent.goodsAct.option.length; i++) {
        if (this.$parent.goodsAct.option[i].num == 0) {
          this.$parent.goodsAct.option.splice(i, 1);
          i--;
        }
      }
      return this.$parent.goodsAct;
    },
  },
  mounted() {
    document.addEventListener("click", this.bodyCloseMenus);
  },
  methods: {
    // 全局点击事件
    bodyCloseMenus() {
      this.detailWin = false;
    },
    // 获取数据
    getData() {
      let datas = {
        detail_id: this.$parent.CostBreakdownId,
      };
      this.$api.orderOneGoodsFee(datas).then((res) => {
        this.datas = res.data;
        this.datas.exchange_rate = Number(this.datas.exchange_rate);
        this.CostBreakdownList = [res.data.list];
        this.EditData();
        this.$forceUpdate();
      });
    },
    // 处理数据
    EditData() {
      this.CostBreakdownList[0].detail = JSON.parse(
        this.CostBreakdownList[0].detail
      );
      this.CostBreakdownList[0].option = JSON.parse(
        this.CostBreakdownList[0].option
      );

      if (
        this.CostBreakdownList[0].confirm_num &&
        this.CostBreakdownList[0].confirm_num > 0
      ) {
        this.CostBreakdownList[0].totalPrice =
          this.CostBreakdownList[0].confirm_price *
          this.CostBreakdownList[0].confirm_num;
      } else {
        this.CostBreakdownList[0].totalPrice =
          this.CostBreakdownList[0].price * this.CostBreakdownList[0].num;
      }
    },
    // 打开明细弹窗
    getdetail() {
      this.detailWin = !this.detailWin;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/css/mixin.scss";

/deep/.el-dialog {
  border-radius: 0px;
  .el-dialog__body {
    padding: 40px 20px 52px;
    .productInformation {
      // 两端对齐
      .alignBothEnds {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .productInformationTitle {
        height: 45px;
        background: #f4f4f4;
        border: 1px solid #e8e8e8;
        padding: 0 29px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .titleName {
          font-weight: bold;
        }
        .titleValue {
        }
      }
      .productInformationBody {
        padding: 29px;
        .informationOpt {
          line-height: 27px;
          font-size: 12px;
          .optName {
            font-weight: 400;
            color: #858585;
          }
          .optPrice {
          }
        }
      }
    }
  }
}
</style>

