<template>
  <div style="padding-bottom: 90px">
    <Dialog ref="dialog" :config="config"></Dialog>

    <div class="main" v-loading="loading">
      <div class="mainTitle">
        <nav>
          <span
            @click="
              form.type = 'cart';
              checked = false;
              totalPrice = 0;
              isTotal = 0;
              $forceUpdate();
              setscource();
              !lists.length ? getAddOrderList() : '';
            "
            :class="form.type == 'cart' ? 'active' : ''"
            >{{ $fanyi("购物车") }}</span
          ><span
            @click="
              form.type = 'inventory';
              checked = false;
              totalPrice = 0;
              isTotal = 0;
              $forceUpdate();
              setscource();
              !lists.length ? getAddOrderList() : '';
            "
            :class="form.type == 'inventory' ? 'active' : ''"
            >{{ $fanyi("商品库") }}</span
          >
        </nav>
        <!-- 表头 -->
        <div class="head" :style="$route.name == 'cart' ? 'top: 130px;' : ''">
          <ul>
            <li class="chexk-box">
              <input
                type="checkbox"
                name=""
                id=""
                v-model="checked"
                @change="allChoice"
              />
              {{ $fanyi("全选") }}
            </li>
            <li class="goods">{{ $fanyi("产品") }}</li>
            <li>{{ $fanyi("详情") }}</li>
            <li>{{ $fanyi("单价") }}</li>
            <li>{{ $fanyi("数量") }}</li>
            <li>{{ $fanyi("总价") }}</li>
            <li>{{ $fanyi("操作") }}</li>
          </ul>
        </div>
      </div>
      <!-- 追加商品显示 -->
      <div
        class="orderAddGoodsBox"
        v-if="lists.length != 0"
        @scroll="scrollBox"
      >
        <div class="list">
          <ul v-for="(listItem, listIndex) in lists" :key="listIndex">
            <li class="checkBox">
              <div class="check">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  :checked="listItem.checked"
                  @change="
                    listItem.checked = !listItem.checked;
                    oddChoice(listIndex);
                  "
                />
              </div>

              <div class="img">
                <router-link
                  :to="'/ProductDetails?goods_id=' + listItem.goods_id"
                  target="_blank"
                >
                  <img :src="listItem.pic" alt="" />
                </router-link>
              </div>
            </li>
            <li class="goods">
              <div class="text">
                <el-popover placement="bottom" width="400" trigger="hover">
                  <p>
                    <router-link
                      :to="'/ProductDetails?goods_id=' + listItem.goods_id"
                      target="_blank"
                      >{{ listItem.goods_title }}
                    </router-link>
                  </p>

                  <p slot="reference">
                    <router-link
                      class="goodsTitle"
                      style="font-weight: 400"
                      :to="'/ProductDetails?goods_id=' + listItem.goods_id"
                      target="_blank"
                      >{{ listItem.goods_title }}
                    </router-link>
                  </p>
                </el-popover>
                <input
                  class="bei"
                  type="text"
                  :placeholder="$fanyi('备注栏')"
                  style="pointer-events: none"
                  v-model="listItem.user_postscript"
                  @blur="editCartRemark(listItem)"
                />
              </div>
            </li>
            <li class="detail">
              <el-popover placement="bottom" trigger="hover">
                <div class="goodsDetailAll">
                  <div
                    class="goodsDetailAllOneBox"
                    v-for="(detailItem, detailIndex) in listItem.detail"
                    :key="detailIndex"
                    :title="detailItem.key + ':' + detailItem.value"
                  >
                    <h1 class="detailTitle">{{ detailItem.key }}</h1>
                    <p>
                      <span class="detailBody">{{ detailItem.value }}</span>
                    </p>
                  </div>
                </div>

                <div slot="reference" class="detailBox">
                  <p
                    v-for="(detailItem, detailIndex) in listItem.detail"
                    :key="detailIndex"
                    :title="detailItem.key + ':' + detailItem.value"
                  >
                    {{ detailItem.key }}:{{ detailItem.value }}
                  </p>
                </div>
              </el-popover>
            </li>
            <li class="price">
              <div class="price-box" v-if="listItem.price_range">
                <!-- 单价 -->
                <p style="text-align: center">
                  <b>
                    <!-- {{ Number(listItem.price) }} -->
                    {{
                      $fun.RMBNumSegmentation(
                        listItem.univalence != 0
                          ? listItem.univalence
                          : Number(listItem.price)
                      )
                    }} </b
                  >￥
                </p>
                <!-- > = x 件 ¥x -->
                <div
                  v-if="listItem.price_range && !listItem.price_range.priceMin"
                >
                  <!-- v-show="
                        listItem.num < priceItem.startQuantity &&
                        listItem.num >
                          (listItem.price_range[priceIndex + 1]
                            ? listItem.price_range[priceIndex + 1].startQuantity
                            : '')
                      " -->
                  <p
                    v-for="(priceItem, priceIndex) in listItem.price_range"
                    :key="priceIndex"
                    v-show="
                      panduanxianshi(listItem.num, listItem.price_range) ==
                        priceIndex
                    "
                  >
                    > =<b> {{ priceItem.startQuantity }}</b> {{ $fanyi("件") }}
                    {{ $fun.RMBNumSegmentation(priceItem.price) }}¥
                  </p>
                </div>
                <!-- 最大值-最小值 -->
                <p v-else>
                  {{ listItem.price_range.priceMax }}~{{
                    listItem.price_range.priceMin
                  }}
                </p>
              </div>
              <div class="price-box" v-else>
                <p style="text-align: center">
                  <b>
                    <!-- {{ Number(listItem.price) }} -->
                    {{
                      $fun.RMBNumSegmentation(
                        listItem.univalence != 0
                          ? listItem.univalence
                          : Number(listItem.price)
                      )
                    }} </b
                  >￥
                </p>
              </div>
            </li>
            <!-- 选择数量 -->
            <li style="text-align: center" class="setNum">
              {{ listItem.num }}
            </li>
            <!-- 总价 -->
            <li style="text-align: center">
              <span>{{ $fun.RMBNumSegmentation(listItem.total) }}¥</span>
            </li>
            <li style="text-align: center"></li>
          </ul>
          <div class="fg"></div>
        </div>
        <div class="loadingBox" v-if="!finish">
          <i class="el-icon-loading"></i>
        </div>
      </div>
      <!-- 购物车无商品 -->
      <div class="cart" v-else>
        <div>
          <img src="../../../../../../assets/img/gwcc.svg" alt="" />
          <div class="nav">
            <p>
              {{
                form.type == "inventory"
                  ? $fanyi("商品库为空")
                  : $fanyi("购物车为空")
              }}
            </p>
            <p>
              <router-link to="/"> {{ $fanyi("去购物") }} |</router-link>
              <router-link to="/user/collection">
                {{ $fanyi("收藏夹") }}</router-link
              >
            </p>
          </div>
        </div>
      </div>
      <div class="foot" :class="{ order: this.$route.name != 'cart' }">
        <input
          type="checkbox"
          name=""
          id=""
          :checked="checked"
          @change="
            checked = !checked;
            allChoice();
          "
        /><b
          @click="
            checked = !checked;
            allChoice();
          "
        >
          {{ $fanyi("全选") }}</b
        >

        <div class="total shuliang">
          <div class="goodsNum">
            <p>
              {{ $fanyi("数量总计") }}： <span>{{ isTotal }}</span>
            </p>
          </div>
        </div>

        <div class="total">
          <p>
            {{ $fanyi("总价") }}:
            <span>
              <span class="price">{{
                $fun.EURNumSegmentation(
                  $fun.ceil(totalPrice * $store.state.exchangeRate)
                )
              }}</span
              >EUR
            </span>
          </p>
          <p>
            <span class="RMBTotal">
              {{ $fun.RMBNumSegmentation($fun.ceil(totalPrice)) }} RMB
            </span>
          </p>
        </div>
        <button
          :disabled="idArr.length == 0"
          :class="{ btn: idArr.length > 0 }"
          @click="zhuijia"
        >
          {{ $fanyi("追加") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { Loading } from "element-ui";
import HomePageTop from "../../../../../../components/head/HomePageTop";
import Dialog from "../../../../../../components/public/Dialog";
export default {
  data() {
    return {
      form: {
        type: "cart",
        page: 1,
        pageSize: 60,
      },
      token: localStorage.getItem("token"),
      checked: false, //全选
      type: 0, // 类型
      num: 0, //
      isTotal: 0, // 选中的商品数
      totalPrice: 0, // 选中的商品总价

      lists: [],
      finish: false,
      canload: true,
      loading: false,

      cartList: [], //购物车数据
      cartPage: 0,
      cartFinish: false,
      inventoryList: [], //商品库数据
      inventoryPage: 0,
      inventoryFinish: false,

      idArr: [], // 选中的ID
      cart: true,
      // 弹窗的属性
      config: {
        width: "480px",
        btnTxt: ["删除", "取消"],
      },
    };
  },
  computed: {
    // // 显示的数据
    // lists() {
    //   return this.form.type == "cart" ? this.cartList : this.inventoryList;
    // },
    // // 是否加载完成
    // finish() {
    //   return this.form.type == "cart" ? this.cartFinish : this.inventoryFinish;
    // },
  },
  components: {
    HomePageTop,
    Dialog,
  },
  created() {
    this.getAddOrderList();
  },

  methods: {
    // 判断显示那个价格区间
    panduanxianshi(num, arr) {
      if (num < arr[0].startQuantity) {
        return 0;
      }
      let s = 0;
      arr.forEach((item, index) => {
        if (num >= arr[index].startQuantity) {
          s = index;
        }
      });
      return s;
    },

    setscource() {
      if (this.form.type == "cart") {
        console.log(this.cartFinish);
        this.finish = this.cartFinish;
        this.lists = this.cartList;
      } else {
        this.lists = this.inventoryList;
        this.finish = this.inventoryFinish;
      }
      // console.log(this.finish, this.cartFinish, this.inventoryFinish);
    },
    scrollBox() {
      let scrollTop = document.getElementsByClassName("orderAddGoodsBox")[0]
        .scrollTop;
      let scrollheight = document.getElementsByClassName("orderAddGoodsBox")[0]
        .scrollHeight;
      // console.log(scrollTop, scrollheight);
      if (scrollheight < scrollTop + 470) {
        // this.$message("dao");
        this.getAddOrderList();
      }
    },

    // 获取追加订单商品列表
    getAddOrderList() {
      if (this.canload && !this.finish) {
        this.canload = false;
        this.loading = true;
        this.form.type == "cart" ? this.cartPage++ : this.inventoryPage++;
        var datas = {
          type: this.form.type,
          page: this.form.type == "cart" ? this.cartPage : this.inventoryPage,
          pageSize: this.form.pageSize,
        };
        if (this.form.type == "cart") {
          datas.not_cart_id = [];
        } else {
          datas.not_inventory_id = [];
        }
        this.$parent.$parent.newList.forEach((shopItem) => {
          shopItem.list.forEach((goodsItem) => {
            if (this.form.type == "cart" && !!goodsItem.cart_id) {
              datas.not_cart_id.push(goodsItem.cart_id);
            } else if (
              this.form.type == "inventory" &&
              !!goodsItem.inventory_id
            ) {
              datas.not_inventory_id.push(goodsItem.inventory_id);
            }
          });
        });
        if (this.form.type == "cart") {
          datas.not_cart_id = datas.not_cart_id.join(",");
        } else {
          datas.not_inventory_id = datas.not_inventory_id.join(",");
        }

        this.$api.orderAddGoodsList(datas).then((res) => {
          //console.log("orderAddGoodsList", res);
          this.canload = true;
          this.loading = false;
          if (res.code != 0) {
            this.form.type == "cart"
              ? (this.cartFinish = true)
              : (this.inventoryFinish = true);
          }
          this.tidyData(res.data);
          res.data.forEach((element) => {
            if (this.form.type == "cart") {
              this.cartList.push(element);
            } else {
              this.inventoryList.push(element);
            }
          });

          if (res.data.length < this.form.pageSize) {
            this.form.type == "cart"
              ? (this.cartFinish = true)
              : (this.inventoryFinish = true);
          }

          this.setscource();
        });
      }
    },

    // 整理数据
    tidyData(resdata) {
      resdata.forEach((item) => {
        // 商品总数
        item.checked = false; // 商品选择
        item.univalence = 0; // 显示单价
        if (item.num == 0) item.num = 1; //如果数量是0，就改成1
        item.total = this.$fun.ceil(item.price * item.num);
        // item.price_range = JSON.parse(item.price); // 字符串转换组数
        //console.log(item);
      });
      //   this.subtotal();
    },

    //根据价格区间计算总价
    subtotal() {
      this.lists.forEach((listItem) => {
        if (listItem.price_range.length == 1) {
          if (!isNaN(listItem.price_range[0])) {
            listItem.total = this.$fun.ceil(
              listItem.num * listItem.price_range[0]
            );
            listItem.univalence = listItem.price_range[0];
          } else {
            listItem.total = this.$fun.ceil(
              listItem.num * listItem.price_range[0].price
            );
            listItem.univalence = listItem.price_range[0].price;
          }
        } else if (listItem.price_range.length == 2) {
          if (listItem.num < listItem.price_range[1].startQuantity) {
            listItem.total = this.$fun.ceil(
              listItem.num * listItem.price_range[0].price
            );
            listItem.univalence = listItem.price_range[0].price;
          } else if (listItem.num >= listItem.price_range[1].startQuantity) {
            listItem.total = this.$fun.ceil(
              listItem.num * listItem.price_range[1].price
            );
            listItem.univalence = listItem.price_range[1].price;
          }
        } else if (listItem.price_range.length == 3) {
          if (listItem.num < listItem.price_range[1].startQuantity) {
            listItem.total = this.$fun.ceil(
              listItem.num * listItem.price_range[0].price
            );
            listItem.univalence = listItem.price_range[0].price;
          } else if (
            listItem.num >= listItem.price_range[1].startQuantity &&
            listItem.num < listItem.price_range[2].startQuantity
          ) {
            listItem.total = this.$fun.ceil(
              listItem.num * listItem.price_range[1].price
            );
            listItem.univalence = listItem.price_range[1].price;
          } else if (listItem.num >= listItem.price_range[2].startQuantity) {
            listItem.total = this.$fun.ceil(
              listItem.num * listItem.price_range[2].price
            );
            listItem.univalence = listItem.price_range[2].price;
          }
        }
      });
    },
    // // 单选
    oddChoice(i) {
      this.isCheckedAll();
      this.commodityTotal();
      this.$forceUpdate();
      //   flag ? (this.lists[i].checked = true) : (this.lists[i].checked = false);
    },

    // // 全选
    allChoice() {
      if (this.checked) {
        this.lists.forEach((item, index) => {
          item.checked = true;
        });
      } else {
        this.lists.forEach((item, index) => {
          item.checked = false;
        });
      }
      this.commodityTotal();
      this.$forceUpdate();
    },
    // 是否全选中
    isCheckedAll() {
      var flag = this.lists.every((item, index) => item.checked == true);
      if (flag == true) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    // // 选中商品数量总数、总价
    commodityTotal() {
      this.totalPrice = 0;
      this.isTotal = 0;
      this.idArr = [];
      this.lists.forEach((item, i) => {
        if (item.checked == true) {
          this.isTotal += item.num;
          this.totalPrice += item.total - 0;
          this.idArr.push(item.cart_id);
        }
      });
      //   //console.log(this.totalPrice, this.isTotal, this.idArr);
    },
    // 删除

    // 备注商品信息
    editCartRemark(item) {
      if (item.beikao == "") return false;
      let datas = {
        cart_id: item.cart_id,
        user_postscript: item.user_postscript,
      };
      this.$api.goodsCartPostscript(datas).then((res) => {
        if (res.code != 0) return false;
        this.$message.success(this.$fanyi("保存成功"));
      });
    },

    // 追加到订单详情页
    zhuijia() {
      let addData = {
        newList: [],
      };
      let goodsNum = 0;
      this.lists.forEach((goodsItem) => {
        if (goodsItem.checked) {
          //console.log(
          //   addData.newList.filter(
          //     (item) => item.shop_name == goodsItem.shop_name
          //   ).length == 0
          // );
          // 计算追加的商品数量
          goodsNum++;
          if (
            !!addData.newList.filter(
              (item) => item.shop_name == goodsItem.shop_name
            ).length == 0
          ) {
            addData.newList.push({
              shop_id: goodsItem.shop_id,
              shop_name: goodsItem.shop_name,
              shop_name_translate: goodsItem.shop_name_translate,
              list: [],
            });
          }
        }
      });
      // 如果追加的商品和订单里的商品数量加起来超过一百就不让他追加
      if (this.$parent.$parent.numberOfStores.goods + goodsNum > 60) {
        return this.$message.warning(this.$fanyi("一个订单最多添加60个商品"));
      }
      this.lists.forEach((goodsItem) => {
        if (goodsItem.checked) {
          addData.newList.forEach((items) => {
            if (goodsItem.shop_id == items.shop_id) {
              items.list.push(goodsItem);
            }
          });
        }
      });
      console.log(addData);
      this.$parent.$parent.addTo(addData);
      this.$parent.$parent.showCart = false;
    },
  },
  // },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../css/mixin";

.main {
  width: $pageWidth;
  margin: 30px auto 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(61, 61, 61, 0.1);
  border-radius: 6px;
  padding: 20px 0;
  .mainTitle {
    height: auto;
  }
  input {
    vertical-align: middle;
  }

  .type {
    button {
      font-size: 36px;
      font-weight: bold;
      color: #000000;
      background-color: transparent;
      line-height: 47px;
      letter-spacing: 2px;
      margin: 20px 0 30px 40px;
    }
  }

  input {
    width: 22px;
    height: 22px;
  }

  .head {
    height: 56px;
    background-color: #eee;
    margin: 0 20px;
    position: sticky;

    z-index: 4;
    ul {
      display: flex;
      line-height: 56px;
      font-size: 13px;
      font-weight: 700;
      .chexk-box {
        text-align: left;
        display: flex;
        align-items: center;
        margin-left: 10px;
        input {
          margin-right: 10px;
        }
      }
      .goods {
        width: 340px;
      }
      li {
        width: 14.28%;
        text-align: center;
        input {
          margin-left: 10px;
        }
      }
    }
  }

  .lists {
    margin: 0 20px;
  }

  .item {
    h4 {
      font-size: 14px;
      line-height: 64px;
      display: flex;
      align-items: center;
      input {
        margin: 0 20px;
      }

      a {
        color: #333;

        &:hover {
          color: #999;
        }
      }

      img {
        margin-left: 5px;
        vertical-align: middle;
        float: none;
      }
    }

    .list {
      .fg {
        width: 1360px;
        height: 10px;
        background: #ededed;
        margin: 0 auto;
      }

      ul {
        height: 150px;
        display: flex;
        padding-top: 20px;
        border-top: 1px solid #ededed;
        .checkBox {
          input {
            margin-left: 20px;
          }
        }
        .goods {
          width: 340px;
          .text {
            height: 110px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            > input {
              padding-left: 10px;
            }
          }
        }
        li {
          width: 14.28%;
          .check {
            height: 80px;
            display: flex;
            align-items: center;
          }
          input {
          }
          &:first-child {
            display: flex;

            .img {
              float: left;
              width: 75px;
              height: 75px;
              margin-left: 15px;
              border-radius: 8px;
              overflow: hidden;

              img {
                width: 75px;
                height: 75px;
              }
            }
          }
          &.detail {
            position: relative;
            > div {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              text-align: left;
              font-size: 14px;
              color: #000000;
              line-height: 19px;

              p {
                width: 150px;
                margin-left: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }

          &:nth-child(4) {
            position: relative;
            // > div {
            //   position: absolute;
            //   left: 50%;
            //   transform: translateX(-50%);
            //   text-align: left;
            //   font-size: 14px;
            //   color: #000000;
            //   line-height: 19px;
            //
            // }
          }
        }

        .del {
          &:hover {
            opacity: 0.3;
          }
        }

        .price {
          .price-box {
            padding: 0 25px;
            p {
              text-align: left;
            }
            font-size: 14px;
            line-height: 19px;
          }
        }
      }
    }
  }

  .cart {
    padding: 50px;
    text-align: center;
    overflow: hidden;
    > div {
      margin: 0 auto;
      width: 440px;
      overflow: hidden;
    }
    img {
      float: left;
      //   margin-left: 280px;
    }

    .nav {
      float: left;
      margin: 50px 0 0 30px;

      p {
        font-size: 20px;
        line-height: 50px;

        a {
          color: $homePageSubjectColor;
          font-size: 16px;
        }
      }
    }
  }

  .foot {
    width: 1400px;
    height: 100px;
    background: #ffffff;
    padding-right: 20px;
    box-shadow: 0px 0px 10px 0px rgba(61, 61, 61, 0.1);
    border-radius: 6px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    line-height: 50px;
    font-weight: 700;
    z-index: 99;
    display: flex;
    align-items: center;

    input {
      margin-left: 40px;
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }

    b {
      cursor: pointer;
      font-size: 14px;
      color: #888888;
      line-height: 19px;

      user-select: none;
      &.del {
        font-size: 14px;
        color: $homePageSubjectColor;
        line-height: 19px;
        margin-left: 40px;
      }
    }

    .total {
      // margin: 0 0 0 auto;
      font-weight: 12px;
      font-weight: 500;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      padding-left: 0px;
      &.shuliang {
        margin: 0 40px 0 auto;
      }
      p {
        display: flex;
        align-items: center;
        font-size: 14px;
        height: 26px;
        .price {
          color: #ffa018;
          font-weight: bold;
          font-size: 24px;
          max-width: 246px;
          // flex-direction: column;

          span {
            font-size: 24px;
          }
        }
        .RMBTotal {
          font-weight: 400;
          font-size: 14px;
        }
      }
      span {
        font-size: 14px;
        font-weight: 700;
        // margin: 0 10px;
      }
      .goodsNum {
        margin: 0 0 0 250px;
        position: relative;
        line-height: 30px;
        font-size: 14px;
      }
    }
    i {
      margin: 0 20px;
    }

    button {
      width: 140px;
      height: 60px;
      background: #47bd9b;
      border-radius: 4px;
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      line-height: 24px;

      cursor: not-allowed;
      margin-left: 20px;
    }

    .btn {
      background-color: #5bb578;
      cursor: pointer;
    }
  }

  .order {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
.addTab {
  width: 1350px;
  margin: 0 auto;
}

nav {
  height: 50px;
  display: flex;
  width: 1350px;
  margin-left: 20px;
  > span {
    // width: 130px;
    padding: 10px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #000000;
    line-height: 24px;

    transition: 0.3s;
    cursor: pointer;
    &.active {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      line-height: 36px;
    }
  }
}

.orderAddGoodsBox {
  height: 420px;
  overflow: auto;
  margin: 0 auto;
  width: 1370px;
  .list {
    width: 100%;
    .fg {
      margin-right: 0;
      width: 1360px;
      height: 10px;
      margin-right: 0 !important;
      background: #ededed;
    }

    ul {
      height: 150px;
      margin: 0 auto;
      width: 1340px;
      display: flex;
      padding-top: 20px;
      border-top: 1px solid #ededed;
      .checkBox {
        input {
          margin-left: 15px;
        }
      }
      .goods {
        width: 340px;
        .text {
          height: 110px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          > input {
            padding-left: 10px;
          }
        }
      }
      li {
        width: 14.28%;
        .check {
          height: 80px;
          display: flex;
          align-items: center;
        }
        input {
        }
        &:first-child {
          display: flex;

          .img {
            float: left;
            width: 75px;
            height: 75px;
            margin-left: 15px;
            border-radius: 8px;
            overflow: hidden;

            img {
              width: 75px;
              height: 75px;
            }
          }
        }
        &.detail {
          position: relative;
        }

        &:nth-child(4) {
          position: relative;
          // > div {
          //   position: absolute;
          //   left: 50%;
          //   transform: translateX(-50%);
          //   text-align: left;
          //   font-size: 14px;
          //   color: #000000;
          //   line-height: 19px;
          //
          // }
        }
        .bei {
          width: 300px;
          height: 30px;
          border-radius: 4px;
          border: 1px solid #e4e7ed;
        }
        span {
          color: #ffa018;
          font-weight: 700;
        }
      }

      .del {
        &:hover {
          opacity: 0.3;
        }
      }

      .price {
        .price-box {
          padding: 0 25px;
          p {
            text-align: left;
          }
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
  .loadingBox {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 30px;
      color: #757575;
    }
  }
}
.detailBox {
  text-align: left;
  font-size: 14px;
  color: #000000;
  line-height: 19px;

  margin-left: 20px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  p {
    width: 150px;
    text-align: left;
    font-weight: 400;
  }
}
.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;
  .goodsDetailAllOneBox {
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    p {
      margin-left: 20px;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
}
.goodsTitle {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  &:hover {
    color: $homePageSubjectColor;
    text-decoration: underline;
  }
}
</style>
