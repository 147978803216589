
<template>
  <div>
    <Dialog ref="dialog" :config="config">
      <div class="tishiCon">
        <p>
          {{
            $fanyi(
              "为了提供最好的进口报价，一旦产品到达我们的仓库，就会计算出准确的运费和其他费用报价，所以现在你只支付产品。"
            )
          }}
        </p>
        <p>
          {{
            $fanyi(
              "由于货物的分组和其他数据，我们可以提供更好的装运的最终报价。"
            )
          }}
        </p>
        <p>
          {{
            $fanyi(
              "这一细分包括产品的质量控制、合同规定的额外服务，以及在适当情况下合同规定的其他费用。"
            )
          }}
        </p>
      </div>
      <div class="foot">
        <button @click="cancel">{{ this.$fanyi("已理解") }}</button>
      </div>
    </Dialog>
  </div>
</template>
  <script>
import Dialog from "@/components/public/Dialog.vue";
import fanyi from "@/utlis/language";
export default {
  data() {
    return {
      config: {
        top: "20vh",
        width: "733px",
        title: this.$fanyi("为什么现在估计装运?"),
        btnTxt: [],
      },
    };
  },
  components: { Dialog },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    cancel() {
      this.$refs.dialog.cancel();
    },
    open() {
      this.$refs.dialog.open(
        "",
        () => {},
        () => {}
      );
    },
  },
};
</script>
  <style lang='scss' scoped='scoped'>
@import "../../../../../../css/mixin";
/deep/.el-dialog__body {
  padding-bottom: 20px !important;
}
.tishiCon {
  margin: -20px auto 0;
  font-size: 20px;
  font-weight: 400;
  color: #2f2f2f;
  line-height: 30px;
  p {
    word-break: normal;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
.foot {
  padding-top: 45px;
  margin-bottom: -20px;
  button {
    width: 190px;
    height: 45px;
    background: #1c2899;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    font-size: 16px;
    color: white;
  }
}
</style>
  