<!-- 业务回复客户弹窗 -->
<template>
  <div>
    <Dialog ref="dialog" :config="config">
      <div class="pinLunBox">
        <div class="boxHead dip">
          Por
          <b style="margin-left:5px">{{ $store.state.userInfo.user_name }}</b>
          <b style="margin-left:auto">{{ order_time_node }}</b>
        </div>
        <div class="boxCon">
          {{ txt }}
        </div>
      </div>
      <div class="footerBox flex jend">
        <button class="bgBlueBtn linkUs" @click="$fun.clickJs()">
          {{ $fanyi("联系代理") }}
        </button>
      </div>
    </Dialog>
  </div>
</template>
<script>
import Dialog from "@/components/public/newCssDialog.vue";
export default {
  data() {
    return {
      config: {
        top: "20vh",
        width: "643px",
        title: "Comentarios",
        btnTxt: [],
      },
      txt: "",
      order_time_node: "",
    };
  },
  components: { Dialog },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    cancel() {
      this.$refs.dialog.cancel();
    },
    open(y_reply, order_time_node) {
      this.txt = y_reply;
      this.order_time_node = order_time_node;
      this.$refs.dialog.open(
        "",
        () => {},
        () => {}
      );
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin";
/deep/.el-dialog__body {
  padding: 0 24px !important;
}
.pinLunBox {
  padding: 24px;
  gap: 16px;
  border-radius: 8px;
  background: var(--Gray-50, #f9fafb);
  .boxHead {
    margin-bottom: 16px;
    color: var(--Gray-500, #667085);
    b {
    }
  }
  .boxCon {
    font-size: 16px;
    color: var(--Gray-950, #0c111d);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    min-height: 200px;
  }
}
.footerBox {
  width: 100%;
  margin-top: 24px;
  .linkUs {
    width: 233px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }
}
</style>
