<template>
  <div>
    <Dialog ref="dialog" :config="config">
      <div class="tishiCon">
        <p>
          Para poder ofrecer la mejor oferta posible de importación, la
          cotización exacta del envío y otros gastos, son calculados cuando la
          mercancía llega a nuestros almacenes de China.
        </p>
        <p>
          Una vez nuestro equipo realice la cotización final del envío,
          recibirás un correo electrónico con la información detallada del coste
          y el enlace a la pantalla de pago.
        </p>
      </div>
      <div class="foot">
        <button @click="cancel">{{ this.$fanyi("已理解") }}</button>
      </div>
    </Dialog>
  </div>
</template>
<script>
import Dialog from "@/components/public/Dialog.vue";
export default {
  data() {
    return {
      config: {
        top: "20vh",
        width: "683px",
        title: "¿Cuando se paga el envío?",
        btnTxt: [],
      },
    };
  },
  components: { Dialog },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    cancel() {
      this.$refs.dialog.cancel();
    },
    open() {
      this.$refs.dialog.open(
        "",
        () => {},
        () => {}
      );
    },
  },
};
</script>
<style lang='scss' scoped='scoped'>
@import "../../../../../../css/mixin";
/deep/.el-dialog__body {
  padding: 50px 60px 44px !important;
}
.tishiCon {
  margin: 00px auto 0;
  font-size: 20px;
  font-weight: 400;
  color: #2f2f2f;
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  p {
    word-break: normal;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
.foot {
  padding-top: 65px;
  margin-bottom: -20px;
  button {
    width: 190px;
    height: 45px;
    background: #1c2899;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    font-size: 16px;
    color: white;
  }
}
</style>