<!-- 替换商品 -->
<template>
  <div>
    <div class="scrollbgcssBox">
      <div class="zhezhao">
        <!-- 右侧滚动栏遮罩 -->
      </div>
      <div class="paddiBox">
        <div class="reBoxHead">
          <div class=" flex acenter">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/66852f9348ad0.svg"
              alt=""
            />
            <div>
              <div class="font18">{{ $fanyi("你的一些产品无法上市") }}</div>
              <div class="font14">
                {{ $fanyi("订单号") }}: {{ $route.query.order_sn }}
              </div>
            </div>

            <el-button
              style="margin-left:auto"
              class="fontBlueBtn linkUs"
              @click="$fun.clickJs()"
            >
              <img
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202408/66b1801dbc6bc.svg"
                alt=""
              />
              {{ $fanyi("与代理联系") }}</el-button
            >
          </div>
          <div class="flex acenter headTotalBox">
            <div>({{ replaceGoodsArr.length }}) Incidencias</div>
            <div style="margin-left:auto">
              ({{ canEditQuestionNumber }}) Sustituciones
            </div>
          </div>
        </div>
        <div class="replaceBox">
          <div
            class="goodsRowBox flex "
            v-for="(reItem, reIndex) in replaceGoodsArr"
            :key="reIndex"
            :class="{ greenUl: reItem.itemStatus == 'reSuccessStatus' }"
          >
            <!-- index列 -->
            <div class="indexBox flex acenter">
              <img
                v-if="reItem.itemStatus == 'reSuccessStatus'"
                class="iconBox green"
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/669f95aa3a121.svg"
                alt=""
              />
              <img
                v-else
                class="iconBox"
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/66853469c9ccb.svg"
                alt=""
              />

              <span>{{ reItem.sorting }} </span>
            </div>

            <!-- 商品图片 -->
            <div class="goodsImg">
              <el-popover placement="right" trigger="hover">
                <img
                  :src="reItem.pic"
                  alt=""
                  style="width: 300px; height: 300px"
                />

                <img
                  @click="$fun.toCommodityDetails(reItem.goods_id)"
                  :src="reItem.pic"
                  alt=""
                  slot="reference"
                />
              </el-popover>
            </div>

            <!-- 商品名称 -->
            <div class="retableLi">
              <div class="liTit">{{ $fanyi("产品名") }}</div>
              <el-popover placement="bottom" width="400" trigger="hover">
                {{ reItem.goods_title }}

                <!-- @click="$fun.toCommodityDetails(reItem.goods_id)"   :title="reItem.goods_title"-->
                <div class="liCon" slot="reference">
                  {{ reItem.goods_title }}
                </div>
              </el-popover>
            </div>

            <!-- 属性 -->
            <div class="retableLi">
              <div class="liTit">{{ $fanyi("属性") }}</div>
              <el-popover placement="bottom" trigger="hover">
                <div class="goodsDetailAll">
                  <div
                    class="goodsDetailAllOneBox"
                    v-for="(detailItem, detailIndex) in reItem.detail"
                    :key="detailIndex"
                    :title="detailItem.key + ':' + detailItem.value"
                  >
                    <h1 class="detailTitle">{{ detailItem.key }}</h1>
                    <p>
                      <span class="detailBody">{{ detailItem.value }}</span>
                    </p>
                  </div>
                </div>
                <div slot="reference" class="liCon">
                  <p
                    v-for="(bitem, bindex) in reItem.detail"
                    :key="bitem.key + bindex"
                    :title="bitem.key + ':' + bitem.value"
                    class="goodsDetailOpt ispay"
                  >
                    {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                  </p>
                </div>
              </el-popover>
            </div>

            <!-- 原因的影响 -->
            <div class="retableLi">
              <div class="liTit">{{ $fanyi("原因的影响") }}</div>
              <!-- <el-popover placement="bottom" width="400" trigger="hover"> -->
              <!-- {{ reItem.y_reply }} -->

              <!-- @click="$fun.toCommodityDetails(reItem.goods_id)"   :title="reItem.goods_title"-->
              <div class="liCon" slot="reference">
                <span v-if="reItem.tag_status == 100">
                  Producto infractor (Imitación o falsificación)
                </span>
                <span v-else-if="reItem.tag_status == 200">
                  Sin stock (No hay stock del producto)
                </span>
                <span v-else-if="reItem.tag_status == 300">
                  Sin certificado (No tiene certificado CE)
                </span>
              </div>
              <!-- </el-popover> -->
            </div>
            <div class="btnBox flex acenter">
              <template
                v-if="
                  ['noNumquestionReStatus', 'irreplaceableStatus'].includes(
                    reItem.itemStatus
                  )
                "
              >
                <span
                  class="flex acenter irreplaceableBox"
                  v-if="
                    !reItem.order_detail_replace ||
                      !reItem.order_detail_replace.length
                  "
                >
                  <el-popover
                    placement="bottom"
                    width="331"
                    trigger="hover"
                    popper-class="orderDetailPopover"
                  >
                    <div class="PoheadBox">
                      <img
                        slot="reference"
                        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/66853469c9ccb.svg"
                        alt=""
                      />
                      <span>{{ $fanyi("不可替代") }}</span>
                    </div>
                    <div class="PobodyBox">
                      {{
                        $fanyi(
                          "我们很抱歉。我们还没有发现类似的产品。请联系我们的代理解决问题。"
                        )
                      }}
                    </div>
                    <img
                      class="poIcon"
                      slot="reference"
                      src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/66853469c9ccb.svg"
                      alt=""
                    />
                  </el-popover>

                  {{ $fanyi("不可替代") }}
                </span>

                <span
                  class="flex acenter irreplaceableBox"
                  v-if="reItem.replace_status == 1"
                >
                  <el-popover
                    placement="bottom"
                    width="331"
                    trigger="hover"
                    popper-class="orderDetailPopover"
                  >
                    <div class="PoheadBox">
                      <img
                        slot="reference"
                        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/66853469c9ccb.svg"
                        alt=""
                      />
                      <span>{{ $fanyi("未替代产品") }}</span>
                    </div>
                    <div class="PobodyBox">
                      {{ $fanyi("产品没有更换，您的订单总额已经扣除。") }}
                    </div>
                    <img
                      class="poIcon"
                      slot="reference"
                      src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/66853469c9ccb.svg"
                      alt=""
                    />
                  </el-popover>

                  {{ $fanyi("未替代产品") }}
                </span>
              </template>
              <template v-else-if="reItem.itemStatus == 'questionReStatus'">
                <div class="qudaibtnBox">
                  <el-button
                    type="primary"
                    class="bgBlueBtn qudai"
                    @click="openReplace(reItem)"
                  >
                    <img
                      src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/669fa2d522d48.svg"
                      alt=""
                    />
                    {{ $fanyi("取代") }}
                  </el-button>
                  <el-popover
                    placement="bottom"
                    width="331"
                    trigger="hover"
                    popper-class="orderDetailPopover"
                  >
                    <div class="PoheadBox">
                      <img
                        slot="reference"
                        src="@/assets/icon/wenhao.svg"
                        alt=""
                      />
                      <span>{{ $fanyi("更换产品说明") }}</span>
                    </div>
                    <div class="PobodyBox">
                      {{ $fanyi("用功能和价格相似的产品替换你的侵权产品。") }}
                    </div>
                    <img
                      class="poIcon"
                      slot="reference"
                      src="@/assets/icon/wenhao.svg"
                      alt=""
                    />
                  </el-popover>
                </div>

                <div style="margin-left:32px" class="qudaibtnBox">
                  <button
                    class="bgRedBtn qudai"
                    @click="
                      operationID = reItem.id;
                      oldGoodsData = JSON.parse(JSON.stringify(reItem));
                      $refs.shanChuODialog.open('', () => {}, '', {
                        width: '488px',
                        showBtn: false,
                      });
                    "
                  >
                    <img
                      src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/669fa44dcd28e.svg"
                      alt=""
                    />
                    {{ $fanyi("选中移出") }}
                  </button>
                  <el-popover
                    placement="bottom"
                    width="331"
                    trigger="hover"
                    popper-class="orderDetailPopover"
                  >
                    <div class="PoheadBox">
                      <img
                        slot="reference"
                        src="@/assets/icon/help-circle.svg"
                        alt=""
                      />
                      <span>{{ $fanyi("消除产品") }}</span>
                    </div>
                    <div class="PobodyBox">
                      {{
                        $fanyi(
                          "将产品从购物车中移除。产品的金额将从订单的总成本中扣除。"
                        )
                      }}
                    </div>
                    <div slot="reference">
                      <img
                        class="poIcon"
                        src="@/assets/icon/help-circle.svg"
                        alt=""
                      />
                    </div>
                  </el-popover>
                </div>
              </template>
              <template v-else-if="reItem.itemStatus == 'reSuccessStatus'">
                <div class="flex acenter">
                  <el-button
                    v-if="bendi"
                    @click="
                      operationID = reItem.id;
                      cancelReplaceProduct();
                    "
                    >取消替换(本地显示)</el-button
                  >
                  <button
                    class="bgRedBtn qudai"
                    @click="
                      opencancelReplaceProductDia(reItem);
                      operationID = reItem.id;
                    "
                  >
                    {{ $fanyi("取消替换") }}
                  </button>
                  <el-popover
                    placement="bottom"
                    width="331"
                    trigger="hover"
                    popper-class="orderDetailPopover"
                  >
                    <div class="PoheadBox">
                      <img
                        slot="reference"
                        src="@/assets/icon/help-circle.svg"
                        alt=""
                      />
                      <span>{{ $fanyi("取消替换") }}</span>
                    </div>
                    <div class="PobodyBox">
                      {{
                        $fanyi(
                          "如果您取消更换产品，它将从您的购物车中删除，并自动从您的商品总额中扣除。"
                        )
                      }}
                    </div>
                    <img
                      class="poIcon"
                      slot="reference"
                      src="@/assets/icon/help-circle.svg"
                      alt=""
                      style="margin-left:16px"
                    />
                  </el-popover>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 替换商品弹窗 -->
    <el-dialog
      :title="$fanyi('更换产品')"
      custom-class="replaceGoodsBox"
      :visible.sync="dialogTableVisible"
      width="908px"
    >
      <div class="flex jsb acenter">
        <div class="reGoodsBox">
          <div class="flex jsb" style="margin-bottom:16px">
            <img :src="oldGoodsData.pic" alt="" class="regoodsImg" />
            <div class="riBox">
              <div class="goodsTitle ">
                {{ oldGoodsData.goods_title }}
              </div>
              <div class="priceBox">
                Precio/unidad: {{ oldGoodsData.confirm_price }}€
              </div>
              <div class="numBox">
                Unidades: {{ oldGoodsData.num }} Disponibles:
                {{ oldGoodsData.confirm_num }}
              </div>
              <div class="totalBox">
                {{ $fanyi("总价格") }}:
                {{
                  $fun.ceil(
                    oldGoodsData.confirm_num * oldGoodsData.confirm_price
                  )
                }}€
              </div>
            </div>
          </div>
          <el-button
            class="bgTransparentBtn reBtn"
            @click="
              $fun.routerToPage(
                '/ProductDetails?goods_id=' + oldGoodsData.goods_id,
                true
              )
            "
          >
            {{ $fanyi("看到产品") }}
          </el-button>
          <el-button class="bgGrayBtn reBtn" @click="doNotReplace">
            {{ $fanyi("非替代") }}
          </el-button>
        </div>
        <div>
          <img
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/66860d1ccd17e.svg"
            alt=""
          />
        </div>
        <div class="reGoodsBox green">
          <div class="flex jsb" style="margin-bottom:16px">
            <img :src="newGoodsData.pic" alt="" class="regoodsImg" />
            <div class="riBox">
              <div class="goodsTitle ">
                {{ newGoodsData.goods_title }}
              </div>
              <div class="priceBox">
                Precio/unidad: {{ newGoodsData.confirm_price }}€
              </div>
              <div class="numBox">
                Unidades: {{ newGoodsData.num }} Disponibles:
                {{ newGoodsData.confirm_num }}
              </div>
              <div class="totalBox">
                {{ $fanyi("总价格") }}:
                {{
                  $fun.ceil(
                    newGoodsData.confirm_num * newGoodsData.confirm_price
                  )
                }}€
              </div>
            </div>
          </div>
          <el-button
            class="bgGrayBtn reBtn"
            @click="
              $fun.routerToPage(
                '/ProductDetails?goods_id=' + newGoodsData.goods_id,
                true
              )
            "
          >
            {{ $fanyi("看到产品") }}
          </el-button>
          <el-button
            class="bgBlueBtn reBtn"
            @click="
              $refs.queRenODialog.open('', () => {}, '', {
                width: '488px',
                showBtn: false,
                title: $fanyi('Sustitución同样'),
              })
            "
          >
            {{ $fanyi("取代") }}
          </el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 替换商品确认弹窗 -->
    <oDialog ref="queRenODialog">
      <div>
        <div class="fontBox">
          <p style="margin-bottom:20px">
            {{ $fanyi("你的产品将被替换。") }}
          </p>
          <p>
            {{ $fanyi("您的订单价格将自动更新。") }}
          </p>
        </div>
        <div class="fontbtnList flex">
          <el-button class="bgGrayBtn" @click="$refs.queRenODialog.cancel()">{{
            $fanyi("取消")
          }}</el-button>

          <el-button class="bgBlueBtn" @click="confirmeElReemplazo">{{
            $fanyi("已理解")
          }}</el-button>
        </div>
      </div>
    </oDialog>

    <!-- 不替换商品确认弹窗 -->
    <oDialog ref="ODialog">
      <div>
        <div class="fontBox">
          <p>
            {{
              $fanyi("如果您不更换产品，产品成本将自动从您的订单总成本中扣除。")
            }}
          </p>
          <p>
            {{ $fanyi("如果您不确定，请与您的代理联系。") }}
          </p>
        </div>
        <div class="fontbtnList flex">
          <el-button class="bgGrayBtn" @click="cancelNotRe">{{
            $fanyi("取消")
          }}</el-button>

          <el-button class="bgBlueBtn" @click="replaceStatusSave">{{
            $fanyi("已理解")
          }}</el-button>
        </div>
      </div>
    </oDialog>

    <!-- 取消替换商品确认弹窗 -->
    <oDialog ref="quXiaoODialog">
      <div>
        <div class="fontBox">
          {{
            $fanyi(
              "如果您取消更换产品，它将从您的购物车中删除，并自动从您的商品总额中扣除。"
            )
          }}
        </div>
        <div class="fontbtnList flex">
          <el-button class="bgGrayBtn" @click="quXiaoODialogclose()">{{
            $fanyi("取消")
          }}</el-button>

          <button class="bgRedBtn" @click="replaceStatusSave">
            {{ $fanyi("取消替换") }}
          </button>
        </div>
      </div>
    </oDialog>

    <!-- 删除商品确认弹窗 -->
    <oDialog ref="shanChuODialog">
      <div>
        <div class="fontBox">
          {{
            $fanyi("取消替换您的产品将从购物车中删除，并自动从您的订单中删除。")
          }}
        </div>
        <div class="fontbtnList flex">
          <el-button class="bgGrayBtn" @click="$refs.shanChuODialog.cancel()">{{
            $fanyi("取消")
          }}</el-button>

          <button class="bgRedBtn" @click="replaceStatusSave()">
            {{ $fanyi("选中移出") }}
          </button>
        </div>
      </div>
    </oDialog>
  </div>
</template>
<script>
import oDialog from "@/components/public/newCssDialog.vue";

export default {
  props: {
    replaceGoodsArr: {
      default: [],
    },
    canEditQuestionNumber: {
      default: 0,
    },
  },
  data() {
    return {
      oldGoodsData: {}, //旧的商品数据
      newGoodsData: {}, //新的商品数据
      dialogTableVisible: false,

      operationID: "",
    };
  },
  components: {
    oDialog,
  },
  computed: {
    bendi() {
      let flog = false;
      if (
        location.href.indexOf("http://localhost:8080") != -1 ||
        location.href.indexOf("http://localhost:8081") != -1
      ) {
        return true;
      }
      return flog;
    },
  },
  created() {},
  mounted() {
    // this.$refs.ODialog.open("", () => {}, "", {
    //   width: "488px",
    //   showBtn: false,
    // });
  },
  methods: {
    // 删除等待付款状态异常商品
    deleteReplaceProduct() {
      this.$emit("fullscreenLoading", true);
      this.$api
        .replaceGoodsDeleteOrderDetail({ order_detail_id: this.operationID })
        .then((res) => {
          this.$emit("fullscreenLoading", false);
          ////console.log('事件名',res)
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          //接下来的操作
          this.$refs.shanChuODialog.cancel();
          this.$emit("pageLoad");
        });
    },

    // 打开取消替换商品确认弹窗
    opencancelReplaceProductDia(reItem) {
      this.oldGoodsData = JSON.parse(JSON.stringify(reItem));
      this.$refs.quXiaoODialog.open("", () => {}, "", {
        width: "488px",
        showBtn: false,
      });
    },
    quXiaoODialogclose() {
      this.$refs.quXiaoODialog.cancel();
    },
    // 取消替换商品
    cancelReplaceProduct() {
      this.$emit("fullscreenLoading", true);
      this.$api
        .replaceGoodsRestore({
          order_detail_id: this.operationID,
        })
        .then((res) => {
          this.$emit("fullscreenLoading", false);
          if (res.code != 0) {
            return this.$message.error(res.msg);
          }
          this.$refs.quXiaoODialog.cancel();
          this.$emit("pageLoad");
        });
    },
    // 确认选择该商品事件
    confirmeElReemplazo() {
      this.$emit("fullscreenLoading", true);
      this.$api
        .replaceGoods({
          replace_id: this.newGoodsData.id,
        })
        .then((res) => {
          if (res.code != 0) {
            this.$emit("fullscreenLoading", false);
            return this.$message.error(res.msg);
          }
          this.dialogTableVisible = false;
          this.$Mmsg(
            this.$fanyi("您的产品已成功更换，感谢您信任Rakumart!"),
            "success"
          );
          this.$refs.queRenODialog.cancel();
          this.$emit("pageLoad");
        });
      return;
    },
    // 不替换商品
    replaceStatusSave() {
      this.$emit("fullscreenLoading", true);
      this.$api
        .replaceStatusSave({
          order_detail_id: this.oldGoodsData.id,
        })
        .then((res) => {
          if (res.code != 0) {
            this.$emit("fullscreenLoading", false);
            this.dialogTableVisible = false;
            return this.$message.error(res.msg);
          }
          this.dialogTableVisible = false;
          this.$refs.ODialog.cancel();
          this.$refs.shanChuODialog.cancel();
          this.$refs.quXiaoODialog.cancel();
          this.$Mmsg(
            this.$fanyi("你的产品没有被替换。请检查您的订单。"),
            "warning"
          );
          this.$emit("pageLoad");
        });
    },

    // 打开替换商品弹窗
    openReplace(reItem) {
      this.oldGoodsData = JSON.parse(JSON.stringify(reItem));
      this.newGoodsData = this.oldGoodsData.order_detail_replace[0];
      this.dialogTableVisible = true;
    },

    // 打开不替换商品弹窗
    doNotReplace() {
      this.$refs.ODialog.open("", () => {}, "", {
        width: "488px",
        showBtn: false,
      });
    },

    // 取消不替换商品
    cancelNotRe() {
      this.$refs.ODialog.cancel();
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.scrollbgcssBox {
  background: #fff;
  border-radius: 8px;
  background: #fff;
  border-radius: 8px;
  padding: 0 25px 25px;
  padding-left: 24px;
  padding-right: 12px;
  width: 1400px;
  margin: 0 auto;
  border: 1px solid var(--Gray-300, #d0d5dd);
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  position: relative;
  .zhezhao {
    position: absolute;
    right: 0;
    width: 32px;
    height: 127px;
    background-color: white;
  }
}
.paddiBox {
  max-height: 544px;
  padding-right: 12px;
  overflow: auto;

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 8px;
    background: var(--Gray-200, #eaecf0);
  }
  &::-webkit-scrollbar-track {
    border-radius: 8px;
  }
}
.reBoxHead {
  position: sticky;
  top: 0px;
  padding-top: 25px;
  background-color: white;

  img {
    width: 34px;
    height: 34px;
    margin-right: 16px;
  }
  .font18 {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
  }
  .font14 {
    font-size: 14px;
    line-height: 20px;
  }
  .linkUs {
    min-width: 252px;
    margin-left: 32px;
    padding: 10px 16px;
    border-radius: 8px;
    border: 2px solid var(--Rakumart-300, #77b2ff);
    color: #1355ab;
    /deep/span {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
  }
}
.replaceBox {
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  .goodsRowBox {
    border: 2px solid var(--Error-500, #f04438);

    padding: 16px 32px;
    border-radius: 8px;
    background: var(--Error-25, #fffbfa);
    margin-bottom: 15px;
    .indexBox {
      width: 75px;
      .iconBox {
        width: 20px;
        margin-right: 16px;
      }
      .green {
        width: 20px;
      }
    }

    .goodsImg {
      flex: 0 0 95px;
      height: 95px;
      margin-right: 16px;
      overflow: hidden;
      border-radius: 8px;
      img {
        width: 100%;
        height: 95px;
      }
    }

    .retableLi {
      width: 235px;
      padding-right: 61px;
      .liTit {
        margin-bottom: 15px;
        color: #98a2b3;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px; /* 150% */
      }
      .liCon {
        @extend .text-overflow;
        color: var(--Gray-950, #0c111d);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
    .btnBox {
      margin-left: auto;
      .qudai {
        // min-width: 110px;
        padding: 0px 17px;
        font-size: 14px;
        font-weight: 700;
        min-height: 44px;
        img {
          margin-right: 4px;
        }
        /deep/span {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            margin-left: 4px;
            margin-right: 4px;
            width: 20px;
          }
        }
      }
      .disabledcss {
        border: 1px solid var(--Gray-200, #eaecf0);
        background: var(--Gray-100, #f2f4f7);
        color: #98a2b3;
        /* Shadows/shadow-xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
      .irreplaceableBox {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #f04438;
        font-size: 14px;
        font-weight: 600;
        /deep/span {
          display: flex;
          justify-content: center;
          align-items: center;
          // border: solid;
          // height: 20px;
        }
        img {
          margin-right: 8px;
        }
      }
    }
  }
  .goodsRowBox.greenUl {
    border-color: #17b26a;
    background-color: #f6fef9;
  }
}

.reGoodsBox {
  background-color: white;
  border-radius: 16px;
  width: 400px;
  padding: 16px;
  border: 2px solid var(--Error-200, #fecdca);
  background: var(--Error-25, #fffbfa);
  .regoodsImg {
    width: 148px;
    height: 137px;
    border-radius: 8px;
    margin-right: 15px;
  }
  .riBox {
    color: var(--Gray-700, #344054);
    .goodsTitle {
      @extend .text-overflow;
      margin-bottom: 16px;
      color: #475467;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }
    .priceBox {
      font-size: 14px;
      line-height: 20px; /* 142.857% */
    }
    .numBox {
      font-size: 12px;
      line-height: 18px; /* 150% */
      margin-bottom: 16px;
    }
    .totalBox {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
    }
  }
  .reBtn {
    height: 40px;
    width: 100%;
    font-weight: 700;
    font-size: 14px;
    margin: 0 0 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.reGoodsBox.green {
  border: 2px solid var(--Success-300, #75e0a7);
  background: var(--Success-25, #f6fef9);
}

.fontBox {
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  padding: 0 24px 45px;
  p:first-child {
    margin-bottom: 17px;
  }
}
.fontbtnList {
  padding: 0 24px 24px;
  .el-button,
  button {
    font-weight: 700;
    margin-left: 0;
    margin-right: 16px;
    padding: 12px 15px;
    width: 208px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.poIcon {
  width: 20px;
  margin-left: 16px;
}
.qudaibtnBox {
  display: flex;
  justify-content: center;
  align-items: center;
  /deep/.el-popover__reference-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
<style lang="scss">
.replaceGoodsBox {
  border-radius: 16px;

  .el-dialog__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
    height: 85px;
    .el-dialog__headerbtn {
      position: none;
      font-size: 20px;
    }
  }
  .el-dialog__body {
    padding: 0 24px 24px;
  }
}
.headTotalBox {
  padding: 16px 0;
  font-size: 16px;
  font-weight: 600;
}
</style>
