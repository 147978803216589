<!-- 不替换的问题商品和没有替换链接的问题商品列表 -->
<template>
  <div>
    <div class="main zhu base-w dingDanXiangQing AllGoods">
      <!-- 头部 -->
      <div class="head">
        <span class="flex acenter">
          <span class="headT1" style="margin-right:16px">
            {{ $fanyi("产品丢弃") }}
          </span>
          <span class="headT1"> ({{ discardGoodsNum }}) Productos </span>
        </span>
      </div>
      <!-- 订单列表 -->
      <div class="replaceBox">
        <div
          class="goodsRowBox flex "
          v-for="(reItem, reIndex) in errorListData"
          :key="reIndex"
          :class="{ greenUl: reItem.itemStatus == 'reSuccessStatus' }"
        >
          <!-- index列 -->
          <div class="indexBox flex acenter">
            <img
              v-if="reItem.itemStatus == 'reSuccessStatus'"
              class="iconBox green"
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/669f95aa3a121.svg"
              alt=""
            />
            <img
              v-else
              class="iconBox"
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/66853469c9ccb.svg"
              alt=""
            />

            <span>{{ reItem.sorting }} </span>
          </div>

          <!-- 商品图片 -->
          <div class="goodsImg">
            <el-popover placement="right" trigger="hover">
              <img
                :src="reItem.pic"
                alt=""
                style="width: 300px; height: 300px"
              />

              <img
                @click="$fun.toCommodityDetails(reItem.goods_id)"
                :src="reItem.pic"
                alt=""
                slot="reference"
              />
            </el-popover>
          </div>

          <!-- 商品名称 -->
          <div class="retableLi">
            <div class="liTit">{{ $fanyi("产品名") }}</div>
            <el-popover placement="bottom" width="400" trigger="hover">
              {{ reItem.goods_title }}

              <!-- @click="$fun.toCommodityDetails(reItem.goods_id)"   :title="reItem.goods_title"-->
              <div class="liCon" slot="reference">
                {{ reItem.goods_title }}
              </div>
            </el-popover>
          </div>

          <!-- 属性 -->
          <div class="retableLi">
            <div class="liTit">{{ $fanyi("属性") }}</div>
            <el-popover placement="bottom" trigger="hover">
              <div class="goodsDetailAll">
                <div
                  class="goodsDetailAllOneBox"
                  v-for="(detailItem, detailIndex) in reItem.detail"
                  :key="detailIndex"
                  :title="detailItem.key + ':' + detailItem.value"
                >
                  <h1 class="detailTitle">{{ detailItem.key }}</h1>
                  <p>
                    <span class="detailBody">{{ detailItem.value }}</span>
                  </p>
                </div>
              </div>
              <div slot="reference" class="liCon">
                <p
                  v-for="(bitem, bindex) in reItem.detail"
                  :key="bitem.key + bindex"
                  :title="bitem.key + ':' + bitem.value"
                  class="goodsDetailOpt ispay"
                >
                  {{ $fun.trunUpperCase(bitem.key) }}:{{ bitem.value }}
                </p>
              </div>
            </el-popover>
          </div>

          <!-- 原因的影响 -->
          <div class="retableLi">
            <div class="liTit">{{ $fanyi("原因的影响") }}</div>
            <!-- <el-popover placement="bottom" width="400" trigger="hover"> -->
            <!-- {{ reItem.y_reply }} -->

            <!-- @click="$fun.toCommodityDetails(reItem.goods_id)"   :title="reItem.goods_title"-->
            <div class="liCon" slot="reference">
              <span v-if="reItem.tag_status == 100">
                Producto infractor (Imitación o falsificación)
              </span>
              <span v-else-if="reItem.tag_status == 200">
                Sin stock (No hay stock del producto)
              </span>
              <span v-else-if="reItem.tag_status == 300">
                Sin certificado (No tiene certificado CE)
              </span>
            </div>
            <!-- </el-popover> -->
          </div>
          <!-- 意见反馈 -->
          <div class="retableLi">
            <div class="liTit">{{ $fanyi("意见反馈") }}</div>
            <div
              class="liCon  yReTotalBox"
              style="cursor: pointer;"
              @click="
                $refs.Comment.open(
                  reItem.y_reply,
                  $parent.datas.order_time_node.datetime_offer
                )
              "
            >
              <img
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202409/66d6ea8140a82.svg"
                alt=""
              />
              <span>({{ !!reItem.y_reply ? 1 : 0 }})</span>
            </div>
          </div>
          <div class="btnBox flex acenter">
            <template>
              <span
                class="flex acenter irreplaceableBox"
                v-if="
                  !reItem.order_detail_replace ||
                    !reItem.order_detail_replace.length
                "
              >
                <el-popover
                  placement="bottom"
                  width="331"
                  trigger="hover"
                  popper-class="orderDetailPopover"
                >
                  <div class="PoheadBox">
                    <img
                      slot="reference"
                      src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/66853469c9ccb.svg"
                      alt=""
                    />
                    <span>{{ $fanyi("不可替代") }}</span>
                  </div>
                  <div class="PobodyBox">
                    {{
                      $fanyi(
                        "我们很抱歉。我们还没有发现类似的产品。请联系我们的代理解决问题。"
                      )
                    }}
                  </div>
                  <img
                    class="poIcon"
                    slot="reference"
                    src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/66853469c9ccb.svg"
                    alt=""
                  />
                </el-popover>

                {{ $fanyi("不可替代") }}
              </span>

              <span
                class="flex acenter irreplaceableBox"
                v-if="reItem.replace_status == 1"
              >
                <el-popover
                  placement="bottom"
                  width="331"
                  trigger="hover"
                  popper-class="orderDetailPopover"
                >
                  <div class="PoheadBox">
                    <img
                      slot="reference"
                      src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/66853469c9ccb.svg"
                      alt=""
                    />
                    <span>{{ $fanyi("未替代产品") }}</span>
                  </div>
                  <div class="PobodyBox">
                    {{ $fanyi("产品没有更换，您的订单总额已经扣除。") }}
                  </div>
                  <img
                    class="poIcon"
                    slot="reference"
                    src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/66853469c9ccb.svg"
                    alt=""
                  />
                </el-popover>

                {{ $fanyi("未替代产品") }}
              </span>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- 业务回复客户弹窗 -->
    <comment ref="Comment" />
  </div>
</template>
<script>
import comment from "./comment.vue";
export default {
  props: {
    errorListData: { default: [] },
    orderStatus: { default: "" },
    datas: { default: {} },
  },
  data() {
    return {};
  },
  components: {
    comment,
  },
  computed: {
    discardGoodsNum() {
      return this.errorListData.length;
    },
  },
  created() {},
  methods: {
    isThisTableGoods(goods) {
      return this.$parent.isThisTableGoods(goods);
    },

    xiBanYaYunFei(item) {
      return this.$parent.xiBanYaYunFei(item);
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
@import "../css/orderdetailCss.scss";
.goodsDetailConBox {
  border: 2px solid var(--Error-500, #f04438);
  margin-bottom: 16px !important;
  border-radius: 8px;
  background: var(--Error-25, #fffbfa);
}
.goodsDetailCon {
  border-top: none !important;
  border-radius: 8px;
}

.replaceBox {
  padding-right: 17px;
  overflow: auto;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  .goodsRowBox {
    border: 2px solid var(--Error-500, #f04438);

    padding: 16px 32px;
    border-radius: 8px;
    background: var(--Error-25, #fffbfa);
    margin-bottom: 15px;
    .indexBox {
      width: 75px;
      .iconBox {
        width: 20px;
        margin-right: 16px;
      }
      .green {
        width: 20px;
      }
    }

    .goodsImg {
      flex: 0 0 95px;
      height: 95px;
      margin-right: 16px;
      overflow: hidden;
      border-radius: 8px;
      img {
        width: 100%;
        height: 95px;
      }
    }

    .retableLi {
      width: 235px;
      padding-right: 61px;
      .liTit {
        margin-bottom: 15px;
        color: #98a2b3;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px; /* 150% */
      }
      .liCon {
        @extend .text-overflow;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        img {
          margin-right: 8px;
        }
      }
      .yReTotalBox {
        display: flex;
        align-items: center;
        span {
          font-size: 14px;
          line-height: 20px; /* 142.857% */
          height: 23px;
        }
      }
    }
    .btnBox {
      margin-left: auto;
      .qudai {
        // min-width: 110px;
        padding: 0px 17px;
        font-size: 14px;
        font-weight: 700;
        min-height: 44px;
        /deep/span {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            margin-left: 4px;
            width: 20px;
          }
        }
      }
      .disabledcss {
        border: 1px solid var(--Gray-200, #eaecf0);
        background: var(--Gray-100, #f2f4f7);
        color: #98a2b3;
        /* Shadows/shadow-xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
      .irreplaceableBox {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #f04438;
        font-size: 14px;
        font-weight: 600;
        /deep/span {
          display: flex;
          justify-content: center;
          align-items: center;
          // border: solid;
          // height: 20px;
        }
        img {
          margin-right: 8px;
        }
      }
    }
  }
  .goodsRowBox.greenUl {
    border-color: #17b26a;
    background-color: #f6fef9;
  }
}
</style>
