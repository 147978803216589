var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('el-form',{ref:"formRefdd",staticClass:"firStepForm",attrs:{"model":_vm.formregister,"rules":_vm.formRules}},[_c('ul',[_c('li',[_c('el-form-item',{attrs:{"prop":"contacts","label":_vm.$fanyi('收件人')}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formregister.contacts),expression:"formregister.contacts"}],staticClass:"ipt",class:{
                istrueInput: _vm.formInputTrue.contacts,
              },attrs:{"autocomplete":"new-password"},domProps:{"value":(_vm.formregister.contacts)},on:{"focus":function($event){return _vm.validateField('contacts', 'formRefdd')},"blur":function($event){return _vm.validateField('contacts', 'formRefdd')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formregister, "contacts", $event.target.value)}}}),(_vm.formInputTrue.contacts)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])],1),_c('li',[_c('el-form-item',{attrs:{"prop":"address","label":_vm.$fanyi('地址')}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formregister.address),expression:"formregister.address"}],staticClass:"ipt",class:{
                istrueInput: _vm.formInputTrue.address,
              },attrs:{"autocomplete":"new-password"},domProps:{"value":(_vm.formregister.address)},on:{"focus":function($event){return _vm.validateField('address', 'formRefdd')},"blur":function($event){return _vm.validateField('address', 'formRefdd')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formregister, "address", $event.target.value)}}}),(_vm.formInputTrue.address)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])],1),_c('li',[_c('div',{staticClass:"formZiDingYi"},[_c('el-form-item',{attrs:{"prop":"zip_code","label":_vm.$fanyi('邮编')}},[_c('div',{staticClass:"youbian"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formregister.zip_code),expression:"formregister.zip_code"}],staticClass:"ipt",class:{
                    istrueInput: _vm.formInputTrue.zip_code,
                  },attrs:{"autocomplete":"new-password"},domProps:{"value":(_vm.formregister.zip_code)},on:{"focus":function($event){return _vm.validateField('zip_code', 'formRefdd')},"blur":function($event){return _vm.validateField('zip_code', 'formRefdd')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formregister, "zip_code", $event.target.value)}}}),(_vm.formInputTrue.zip_code)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])]),_c('el-form-item',{attrs:{"prop":"city","label":_vm.$fanyi('按人口分列的城市')}},[_c('div',{staticClass:"chengshi"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formregister.city),expression:"formregister.city"}],staticClass:"ipt",class:{
                    istrueInput: _vm.formInputTrue.city,
                  },attrs:{"autocomplete":"new-password"},domProps:{"value":(_vm.formregister.city)},on:{"focus":function($event){return _vm.validateField('city', 'formRefdd')},"blur":function($event){return _vm.validateField('city', 'formRefdd')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formregister, "city", $event.target.value)}}}),(_vm.formInputTrue.city)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])])],1)]),_c('li',[_c('div',{staticClass:"formZiDingYi"},[_c('el-form-item',{attrs:{"prop":"country","label":_vm.$fanyi('国家')}},[_c('div',{staticClass:"formZiDingYi_item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formregister.country),expression:"formregister.country"}],staticClass:"ipt",class:{
                    istrueInput: _vm.formInputTrue.country,
                  },attrs:{"autocomplete":"new-password"},domProps:{"value":(_vm.formregister.country)},on:{"focus":function($event){return _vm.validateField('country', 'formRefdd')},"blur":function($event){return _vm.validateField('country', 'formRefdd')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formregister, "country", $event.target.value)}}}),(_vm.formInputTrue.country)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])]),_c('el-form-item',{attrs:{"prop":"province","label":_vm.$fanyi('省份')}},[_c('div',{staticClass:"formZiDingYi_item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formregister.province),expression:"formregister.province"}],staticClass:"ipt",class:{
                    istrueInput: _vm.formInputTrue.province,
                  },attrs:{"autocomplete":"new-password"},domProps:{"value":(_vm.formregister.province)},on:{"focus":function($event){return _vm.validateField('province', 'formRefdd')},"blur":function($event){return _vm.validateField('province', 'formRefdd')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formregister, "province", $event.target.value)}}}),(_vm.formInputTrue.province)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])])],1)]),_c('li',[_c('el-form-item',{attrs:{"prop":"mobile","label":_vm.$fanyi('电话')}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formregister.mobile),expression:"formregister.mobile"}],staticClass:"ipt",class:{
                istrueInput: _vm.formInputTrue.mobile,
              },attrs:{"autocomplete":"new-password"},domProps:{"value":(_vm.formregister.mobile)},on:{"focus":function($event){return _vm.validateField('mobile', 'formRefdd')},"blur":function($event){return _vm.validateField('mobile', 'formRefdd')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formregister, "mobile", $event.target.value)}}}),(_vm.formInputTrue.mobile)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])],1),_c('li',[_c('el-form-item',{attrs:{"prop":"email","label":_vm.$fanyi('邮件箱')}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formregister.email),expression:"formregister.email"}],staticClass:"ipt",class:{
                istrueInput: _vm.formInputTrue.email,
              },attrs:{"autocomplete":"new-password"},domProps:{"value":(_vm.formregister.email)},on:{"focus":function($event){return _vm.validateField('email', 'formRefdd')},"blur":function($event){return _vm.validateField('email', 'formRefdd')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formregister, "email", $event.target.value)}}}),(_vm.formInputTrue.email)?_c('div',{staticClass:"gou"},[_vm._v("✔")]):_vm._e()])],1)])]),_c('div',{staticClass:"btnList"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.baocun}},[_vm._v(_vm._s(_vm.$fanyi("保存")))]),_c('el-button',{attrs:{"type":"danger"},on:{"click":_vm.closeDialog}},[_vm._v(_vm._s(_vm.$fanyi("取消")))])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }