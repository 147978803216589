import { render, staticRenderFns } from "./SustituciónDeBienes.vue?vue&type=template&id=4617f648&scoped=true&"
import script from "./SustituciónDeBienes.vue?vue&type=script&lang=js&"
export * from "./SustituciónDeBienes.vue?vue&type=script&lang=js&"
import style0 from "./SustituciónDeBienes.vue?vue&type=style&index=0&id=4617f648&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4617f648",
  null
  
)

export default component.exports