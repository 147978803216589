var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main zhu base-w",class:{ AllGoods: _vm.$parent.showAllGoods }},[_c('div',{staticClass:"head"},[_c('span',[_c('font',{staticClass:"headT1"},[_vm._v(_vm._s(_vm.$fanyi("订单内容")))]),_c('span',{staticClass:"orderSnBox"},[_vm._v(_vm._s(_vm.$fanyi("订单号"))+" ："+_vm._s(_vm.$parent.datas.order_sn))])],1)]),_c('div',{staticClass:"order"},[_c('ul',[_c('li',{staticClass:"inputBox",style:(['obligation', 'OrderDetails', 'purchased'].indexOf(
            _vm.$parent.orderStatus
          ) != -1
            ? 'flex: 0 0 186px'
            : ''),on:{"click":function($event){_vm.$parent.checked = !_vm.$parent.checked;
          _vm.$parent.allChoice();}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.checked),expression:"$parent.checked"}],staticClass:"headCheckBox",staticStyle:{"margin-right":"10px"},attrs:{"type":"checkbox","name":"","id":""},domProps:{"checked":Array.isArray(_vm.$parent.checked)?_vm._i(_vm.$parent.checked,null)>-1:(_vm.$parent.checked)},on:{"change":[function($event){var $$a=_vm.$parent.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.$parent, "checked", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.$parent, "checked", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.$parent, "checked", $$c)}},_vm.$parent.allChoice]}}),_vm._v(" "+_vm._s(_vm.$fanyi("全选"))+" ")]),_c('li',{staticClass:"goods",staticStyle:{"padding":"0"}},[_vm._v(_vm._s(_vm.$fanyi("选定的产品")))]),_c('li',{staticClass:"detail"},[_vm._v(_vm._s(_vm.$fanyi("描述")))]),_c('li',{staticClass:"num"},[_c('span',{style:(['obligation', 'OrderDetails', 'purchased'].indexOf(
              _vm.$parent.orderStatus
            ) != -1
              ? 'margin-left: 20px'
              : '')},[_vm._v(_vm._s(_vm.$fanyi("单价")))])]),_c('li',{staticClass:"num"},[_vm._v(_vm._s(_vm.$fanyi("单位")))]),(
          ['obligation', 'OrderDetails', 'purchased', 'purchase'].indexOf(
            _vm.$parent.orderStatus
          ) != -1
        )?_c('li',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.$fanyi("调查在库数量"))+" ")]):_vm._e(),_c('li',{staticClass:"total"},[_vm._v(_vm._s(_vm.$fanyi("总价")))]),_c('li',{staticClass:"options"},[_vm._v(_vm._s(_vm.$fanyi("附加服务")))]),_c('li',{staticClass:"remark"},[_vm._v(_vm._s(_vm.$fanyi("备注")))]),(
          ['obligation', 'purchased', 'purchase'].indexOf(
            _vm.$parent.orderStatus
          ) != -1
        )?_c('li',{staticClass:"constDetail"},[_vm._v(" "+_vm._s(_vm.$fanyi("导入的详细信息"))+" ")]):_vm._e()]),_c('div',{staticClass:"goodsBox",style:(!_vm.$parent.showAllGoods ? 'max-height:300px' : '')},_vm._l((_vm.$parent.newList),function(aitem,aindex){return _c('div',{key:aindex},[_c('h1',[_c('div',{staticClass:"checkedBox",on:{"click":function($event){aitem.checked = !aitem.checked;
              _vm.$parent.shopAll(aindex);}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(aitem.checked),expression:"aitem.checked"}],attrs:{"type":"checkbox","name":"","id":""},domProps:{"checked":Array.isArray(aitem.checked)?_vm._i(aitem.checked,null)>-1:(aitem.checked)},on:{"change":[function($event){var $$a=aitem.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(aitem, "checked", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(aitem, "checked", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(aitem, "checked", $$c)}},function($event){return _vm.$parent.shopAll(aindex)}]}})]),_vm._v(" "+_vm._s(_vm.$fanyi("店铺"))+"："+_vm._s(aitem.shop_name_translate || aitem.shop_name)+" ")]),_c('div',{staticClass:"goodsCon"},_vm._l((aitem.list),function(item,goodsListIndex){return _c('ul',{key:item.id},[_c('li',{staticClass:"inputBox",on:{"click":function($event){item.checked = !item.checked;
                _vm.$parent.radio(aindex);}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.checked),expression:"item.checked"}],attrs:{"type":"checkbox","name":"","id":""},domProps:{"checked":Array.isArray(item.checked)?_vm._i(item.checked,null)>-1:(item.checked)},on:{"change":[function($event){var $$a=item.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "checked", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "checked", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "checked", $$c)}},function($event){return _vm.$parent.radio(aindex)}]}}),_c('span',{staticClass:"rowNumber"},[_vm._v(_vm._s(item.sorting))]),_c('el-popover',{attrs:{"placement":"right","trigger":"hover"}},[_c('img',{staticStyle:{"width":"300px","height":"300px"},attrs:{"src":item.pic,"alt":""}}),_c('img',{attrs:{"slot":"reference","src":item.pic,"alt":""},on:{"click":function($event){return _vm.$fun.toCommodityDetails(item.goods_id)}},slot:"reference"})])],1),_c('li',{staticClass:"goods"},[_c('el-popover',{attrs:{"placement":"bottom","width":"400","trigger":"hover"}},[_vm._v(" "+_vm._s(item.goods_title)+" "),_c('div',{staticClass:"showGoodsTitle",attrs:{"slot":"reference","title":item.goods_title},on:{"click":function($event){return _vm.$fun.toCommodityDetails(item.goods_id)}},slot:"reference"},[_c('font',[_vm._v(_vm._s(item.goods_title))])],1)])],1),_c('li',{staticClass:"detail",class:item.detailWindow == true
                  ? 'active goodsDetail'
                  : 'goodsDetail'},[_c('el-popover',{attrs:{"placement":"bottom","trigger":"hover"}},[_c('div',{staticClass:"goodsDetailAll"},_vm._l((item.detail),function(detailItem,detailIndex){return _c('div',{key:detailIndex,staticClass:"goodsDetailAllOneBox",attrs:{"title":detailItem.key + ':' + detailItem.value}},[_c('h1',{staticClass:"detailTitle"},[_vm._v(_vm._s(detailItem.key))]),_c('p',[_c('span',{staticClass:"detailBody"},[_vm._v(_vm._s(detailItem.value))])])])}),0),(
                    ['temporary', 'editing'].indexOf(_vm.$parent.orderStatus) ==
                      -1
                  )?_c('div',{staticClass:"showDetail",attrs:{"slot":"reference"},slot:"reference"},[_vm._l((item.detail),function(bitem,bindex){return _c('p',{key:bitem.key + bindex,staticClass:"goodsDetailOpt ispay",attrs:{"title":bitem.key + ':' + bitem.value},on:{"click":function($event){$event.stopPropagation();['temporary', 'editing'].indexOf(_vm.$parent.orderStatus) !=
                      -1
                        ? _vm.$parent.openDetailWindow(item)
                        : ''}}},[_vm._v(" "+_vm._s(_vm.$fun.trunUpperCase(bitem.key))+":"+_vm._s(bitem.value)+" ")])}),(
                      ['temporary', 'editing'].indexOf(_vm.$parent.orderStatus) !=
                        -1
                    )?_c('img',{attrs:{"src":require('@/assets/preview_order/baianji.svg'),"alt":""},on:{"click":function($event){$event.stopPropagation();['temporary', 'editing'].indexOf(_vm.$parent.orderStatus) !=
                      -1
                        ? _vm.$parent.openDetailWindow(item)
                        : ''}}}):_vm._e()],2):_vm._e()]),_c('el-popover',{attrs:{"placement":"bottom","trigger":"click"}},[_c('div',{staticClass:"detailWindowBody"},_vm._l((item.pageSpecification),function(goodsGuiGeItem,goodsGuiGeIndex){return _c('div',{key:goodsGuiGeIndex,staticClass:"detailOpt"},[_c('h1',[_vm._v(_vm._s(goodsGuiGeItem.key))]),_c('div',{staticClass:"optClass"},_vm._l((goodsGuiGeItem.value),function(actGuige,actIndex){return _c('span',{key:actIndex,class:actIndex == goodsGuiGeItem.active ? 'active' : '',attrs:{"title":actGuige.name},on:{"click":function($event){goodsGuiGeItem.active = actIndex;
                          _vm.$parent.goodsDetailChoice(
                            item.detail[goodsGuiGeIndex],
                            actGuige,
                            item
                          );
                          _vm.$forceUpdate();}}},[_vm._v(_vm._s(actGuige.name))])}),0)])}),0),(
                    ['temporary', 'editing'].indexOf(_vm.$parent.orderStatus) !=
                      -1
                  )?_c('div',{staticClass:"showDetail",attrs:{"slot":"reference"},slot:"reference"},[_c('div',{staticClass:"showDetailCon"},_vm._l((item.detail),function(bitem,bindex){return _c('p',{key:bitem.key + bindex,staticClass:"goodsDetailOpt ispay",attrs:{"title":bitem.key + ':' + bitem.value}},[_vm._v(" "+_vm._s(_vm.$fun.trunUpperCase(bitem.key))+":"+_vm._s(bitem.value)+" ")])}),0),(
                      ['temporary', 'editing'].indexOf(_vm.$parent.orderStatus) !=
                        -1
                    )?_c('img',{attrs:{"src":require('@/assets/preview_order/baianji.svg'),"alt":""},on:{"click":function($event){['temporary', 'editing'].indexOf(_vm.$parent.orderStatus) !=
                      -1
                        ? _vm.$parent.openDetailWindow(item)
                        : ''}}}):_vm._e()]):_vm._e()])],1),_c('li',{staticClass:"price"},[_c('b',[_vm._v(_vm._s(_vm.$fun.RMBNumSegmentation( _vm.$fun.ceil( (item.confirm_price && item.confirm_price > 0 ? item.confirm_price : item.price) * _vm.$parent.datas.exchange_rate ) ))+"€")]),_c('span',[_vm._v("("+_vm._s(_vm.$fun.RMBNumSegmentation( item.confirm_price && item.confirm_price > 0 ? item.confirm_price : item.price ))+"￥)")])]),_c('li',{staticClass:"num"},[(
                  _vm.$route.query.type == 'obligation' &&
                    ['editing'].indexOf(_vm.$parent.orderStatus) != -1 &&
                    item.num
                )?_c('el-input-number',{attrs:{"step-strictly":"","min":1,"size":"small"},on:{"change":function($event){return _vm.$parent.getTotalPrice(item)}},model:{value:(item.num),callback:function ($$v) {_vm.$set(item, "num", $$v)},expression:"item.num"}}):(
                  ['temporary', 'editing'].indexOf(_vm.$parent.orderStatus) !=
                    -1 && item.confirm_num
                )?_c('el-input-number',{attrs:{"step-strictly":"","min":1,"size":"small"},on:{"change":function($event){return _vm.$parent.getTotalPrice(item)}},model:{value:(item.num),callback:function ($$v) {_vm.$set(item, "num", $$v)},expression:"item.num"}}):(
                  ['temporary', 'editing'].indexOf(_vm.$parent.orderStatus) !=
                    -1 && item.num
                )?_c('el-input-number',{attrs:{"step-strictly":"","min":1,"size":"small"},on:{"change":function($event){return _vm.$parent.getTotalPrice(item)}},model:{value:(item.num),callback:function ($$v) {_vm.$set(item, "num", $$v)},expression:"item.num"}}):_c('span',[_vm._v(_vm._s(item.num))])],1),(
                [
                  'obligation',
                  'OrderDetails',
                  'purchased',
                  'purchase' ].indexOf(_vm.$parent.orderStatus) != -1
              )?_c('li',{staticClass:"num"},[_vm._v(" "+_vm._s(item.confirm_num)+" ")]):_vm._e(),_c('li',{staticClass:"total"},[_c('span',[_vm._v(_vm._s(_vm.$fun.RMBNumSegmentation( _vm.$fun.ceil(item.totalPrice * _vm.$parent.datas.exchange_rate) ))+"€")]),_c('span',[_vm._v("("+_vm._s(_vm.$fun.RMBNumSegmentation(item.totalPrice))+"￥)")])]),_c('li',{staticClass:"options"},[(
                  [
                    'obligation',
                    'purchased',
                    'offer',
                    'purchase',
                    'delete' ].indexOf(_vm.$parent.orderStatus) != -1 && item.option != '[]'
                )?_c('div',{staticClass:"obligationOptionInput",on:{"click":function($event){_vm.$parent.setOrderOption(item);
                  _vm.openOptionDialog(item);}}},_vm._l((item.option),function(optionItem,index){return _c('p',{directives:[{name:"show",rawName:"v-show",value:(optionItem.checked),expression:"optionItem.checked"}],key:index,staticClass:"optList"},[_vm._v(" "+_vm._s(optionItem.tag)+" "+_vm._s(optionItem.name_translate)+"*"+_vm._s(optionItem.num)+" ")])}),0):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                  ['temporary', 'editing'].indexOf(_vm.$parent.orderStatus) != -1
                ),expression:"\n                  ['temporary', 'editing'].indexOf($parent.orderStatus) != -1\n                "}],staticClass:"optionInput",on:{"click":function($event){_vm.$parent.setOrderOption(item);
                  _vm.openOptionDialog(item);}}},[(item.option.length == 0)?_c('div',{staticClass:"inputOption"},[_c('p',{staticClass:"placeHole"},[_c('img',{attrs:{"src":require('@/assets/icon/addOption.png'),"alt":""}}),_vm._v(" "+_vm._s(_vm.$fanyi("追加"))+" ")])]):_c('div',{staticClass:"inputOption haveData"},_vm._l((item.option),function(eeitem,index){return _c('p',{directives:[{name:"show",rawName:"v-show",value:(
                      !!(typeof item.option != 'string') &&
                        eeitem.checked == true
                    ),expression:"\n                      !!(typeof item.option != 'string') &&\n                        eeitem.checked == true\n                    "}],key:index,staticClass:"list"},[_vm._v(" "+_vm._s(eeitem.tag)+" "+_vm._s(eeitem.name_translate)+"*"+_vm._s(eeitem.num)+" ")])}),0)])]),_c('li',{staticClass:"remark"},[(
                  ['temporary', 'editing'].indexOf(_vm.$parent.orderStatus) == -1
                )?_c('div',{staticClass:"showSpan"},[_c('span',[_vm._v(_vm._s(item.client_remark))])]):_vm._e(),(
                  ['temporary', 'editing'].indexOf(_vm.$parent.orderStatus) != -1
                )?_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(item.client_remark),expression:"item.client_remark"}],attrs:{"placeholder":_vm.$fanyi('您可以在这边添加商品备注')},domProps:{"value":(item.client_remark)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "client_remark", $event.target.value)}}}):_vm._e()]),(
                ['obligation', 'purchased', 'purchase'].indexOf(
                  _vm.$parent.orderStatus
                ) != -1
              )?_c('li',{staticClass:"constDetail"},[_c('button',{on:{"click":function($event){return _vm.openCostBreakdown(item.id, item)}}},[_vm._v(" "+_vm._s(_vm.$fanyi("看到细节"))),_c('img',{attrs:{"src":require('@/assets/icon/arrow-right.png'),"alt":""}})])]):_vm._e()])}),0)])}),0),(_vm.$parent.numberOfStores.goods > 1)?_c('div',{staticClass:"showAllGoods",class:{ openBtn: _vm.$parent.showAllGoods }},[_c('span',{on:{"click":function($event){_vm.$parent.showAllGoods = !_vm.$parent.showAllGoods}}},[_vm._v(" "+_vm._s(_vm.$fanyi("部署"))+" ")]),(!_vm.$parent.showAllGoods)?_c('img',{staticClass:"jiahaoIcon",attrs:{"src":require('@/assets/icon/jiahao_fill.svg')},on:{"click":function($event){_vm.$parent.showAllGoods = !_vm.$parent.showAllGoods}}}):_c('img',{staticClass:"jiahaoIcon",attrs:{"src":require('@/assets/icon/jianhao.svg')},on:{"click":function($event){_vm.$parent.showAllGoods = !_vm.$parent.showAllGoods}}})]):_vm._e()]),_c('optionDialog',{ref:"OptionDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }